import { postRequest } from '../../../helpers/request';
import {
  findYieldUnit,
} from '../../../helpers/units';
import { getActualProductionSummaryTrees, flattenTree } from '../../../helpers/trees';
// modules
import productionFarms from './productionFarms';
import deliveries from './delivery';
import integrations from './integrations';
import inventory from './inventory';
import sales from './sales';
import cropPage from './cropPage';
import reporting from './reporting';
import fileImport from './fileImport';
import farms from './farms';
import factors from './factors';
import johndeere from './johndeere';
import salesRecommendations from './salesRecommendations';

export default {
  state: () => ({
    actualProductions: {},
    showChildCommodities: {}, // used to display or hide whole sub tree in data table
    selectedYear: null, // object of the selected year
    unitPreferences: [],
    contractTypes: [],
    farmProfileLoading: true, // Used to check if the page has fetching data
  }),

  getters: {
    hasActualProductions(state) {
      return Object.keys(state.actualProductions).length > 0;
    },

    actualProductionsInPreferredUnits(state, getters, rootState, rootGetters) {
      const { currentUnitPreference } = getters;
      const convert = rootGetters['shared/convert'];
      return Object.entries(state.actualProductions).reduce((acc, [k, v]) => {
        const ap = { ...v };

        // Area Conversion
        const fromAreaUnit = v.area_unit_id;
        const toAreaUnit = currentUnitPreference('Area');
        const newHarvestedArea = convert(v.harvested_area, fromAreaUnit, toAreaUnit);
        const newSeededArea = convert(v.seeded_area, fromAreaUnit, toAreaUnit);
        ap.harvested_area = newHarvestedArea;
        ap.seeded_area = newSeededArea;
        ap.area_unit_id = toAreaUnit.id;

        // Yield Conversion
        const fromYieldUnit = v.yield_unit_id;
        const toYieldUnit = currentUnitPreference('Yield');
        const newYield = convert(v.prod_yield, fromYieldUnit, toYieldUnit, v.commodity_id);
        ap.prod_yield = newYield;
        ap.yield_unit_id = toYieldUnit.id;

        // Production Conversion
        const fromProductionUnit = v.production_unit_id;
        const toProductionUnit = currentUnitPreference('Production');
        const newProduction = convert(v.production, fromProductionUnit, toProductionUnit, v.commodity_id);
        ap.production = newProduction;
        ap.production_unit_id = toProductionUnit.id;

        acc[k] = ap;
        return acc;
      }, {});
    },
    commoditiesRegistered(state, getters, rootState) {
      const { selectedFarmLocations } = rootState.farmLocations;
      let commodityList = [];
      Object.values(state.actualProductions).forEach((ap) => {
        const commodity = rootState.shared.commodities[ap.commodity_id];
        if (selectedFarmLocations === 0 // When would this ever be zero?
          || selectedFarmLocations.includes(ap.farm_location_id)) {
          if (commodityList.indexOf(commodity) === -1) {
            commodityList = [...commodityList, commodity];
          }
        }
      });
      return commodityList;
    },

    filteredYears(state, getters, rootState) {
      const cropYearIds = Object.values(state.actualProductions).map(
        (ap) => ap.crop_year_id,
      );
      return rootState.shared.cropYears.filter((cy) => cropYearIds.includes(cy.id));
    },

    filteredYearsLongName(state, getters) {
      return getters.filteredYears.map((yearObj) => yearObj.long_name);
    },

    filteredCommodities(state, getters, rootState) {
      const commodityIds = Object.values(state.actualProductions).map(
        (ap) => ap.commodity_id,
      );
      return Object.values(rootState.shared.commodities).filter(
        (crop) => commodityIds.includes(crop.id),
      );
    },

    getAllNonHiddenCommodities(state, getters, rootState) {
      if (!rootState.launchDarkly.flags['hidden-crops']) {
        return Object.values(rootState.shared.commodities);
      }
      return Object.values(rootState.shared.commodities).filter(
        (crop) => !crop.is_hidden,
      );
    },

    availableUnits(state, getters, rootState) {
      const { units } = rootState.shared;
      return {
        Area: Object.values(units).filter((u) => u.unit_classification.classification === 'Area'),
        Yield: Object.values(units).filter((u) => u.unit_classification.classification === 'Yield'),
        Production: Object.values(units).filter((u) => ['Mass', 'Volume'].includes(u.unit_classification.classification)),
        Price: Object.values(units).filter((u) => u.unit_classification.classification === 'Price'),
      };
    },

    getDisplayableUnits(state, getters, rootState) {
      const { units } = rootState.shared;
      const displayableUnits = Object.values(units).filter((u) => !u.is_hidden);
      return {
        Area: displayableUnits.filter((u) => u.unit_classification.classification === 'Area'),
        Yield: displayableUnits.filter((u) => u.unit_classification.classification === 'Yield'),
        Production: displayableUnits.filter((u) => ['Mass', 'Volume'].includes(u.unit_classification.classification)),
        Price: displayableUnits.filter((u) => u.unit_classification.classification === 'Price'),
      };
    },

    currentUnitPreference(state, getters, rootState) {
      return (context) => {
        const preference = state.unitPreferences.find(
          (pref) => pref.context === context,
        );
        if (typeof preference === 'undefined') { // missing preference
          switch (context) {
            case 'Area':
              return Object.values(rootState.shared.units).find((unit) => unit.name.toLowerCase() === 'acre');
            case 'Production':
              return Object.values(rootState.shared.units).find((unit) => unit.name.toLowerCase() === 'bushel a');
            case 'Yield': {
              const areaPref = getters.currentUnitPreference('Area');
              const productionPref = getters.currentUnitPreference('Production');
              const preferredUnit = Object.values(rootState.shared.units).find(
                (unit) => unit.name.toLowerCase() === `${productionPref.name.toLowerCase()}/${areaPref.name.toLowerCase()}`,
              );
              return preferredUnit ?? Object.values(rootState.shared.units).find((unit) => unit.name.toLowerCase() === 'bushel a/acre');
            }
            case 'Price': {
              const productionPref = getters.currentUnitPreference('Production');
              const preferredUnit = Object.values(rootState.shared.units).find(
                (unit) => unit.name.toLowerCase() === `dollar/${productionPref.name.toLowerCase()}`,
              );
              return preferredUnit ?? Object.values(rootState.shared.units).find((unit) => unit.name.toLowerCase() === 'dollar/bushel a');
            }
            default:
              throw Error(`Invalid context (${context}) given for current unit preference`);
          }
        } else {
          return rootState.shared.units[preference.unit_id];
        }
      };
    },

    actualProductionLocationFiltered(state, getters, rootState) {
      const { selectedFarmLocations } = rootState.farmLocations;
      return Object.values(getters.actualProductionsInPreferredUnits).filter(
        (ap) => selectedFarmLocations.includes(ap.farm_location_id),
      );
    },

    actualProductionFarmFiltered(state, getters, rootState, rootGetters) {
      const productionFarm = rootGetters['farmProfile/productionFarms/selectedProductionFarm'];
      return Object.values(getters.actualProductionsInPreferredUnits).filter(
        (ap) => productionFarm.id === ap.farm_location_id,
      );
    },

    actualProductionYearFiltered(state, getters) {
      return Object.values(getters.actualProductionsInPreferredUnits)
        .filter((ap) => state.selectedYear.id === ap.crop_year_id);
    },

    actualProductionLocationYearFiltered(state, getters, rootState) {
      const { selectedFarmLocations } = rootState.farmLocations;
      return Object.values(getters.actualProductionsInPreferredUnits)
        .filter((ap) => selectedFarmLocations.includes(ap.farm_location_id)
          && state.selectedYear?.id === ap.crop_year_id);
    },

    preparedTableNodes(state, getters, rootState, rootGetters) {
      const nodes = {};
      getters.actualProductionFarmFiltered.forEach((ap) => {
        if (!(ap.commodity_id in nodes)) {
          // first time seeing crop
          nodes[ap.commodity_id] = {
            years: {},
            id: ap.id, // actual production record id
            commodity_id: ap.commodity_id,
            parent_commodity_id: rootState.shared.commodities[ap.commodity_id].parent_id,
          };
        }
        const year = rootState.shared.cropYears.find(
          (y) => y.id === ap.crop_year_id,
        ).long_name;

        if (year in nodes[ap.commodity_id].years) {
          const previous = nodes[ap.commodity_id].years[year];
          const prod = previous.production + ap.production;
          const seededArea = previous.seeded_area + ap.seeded_area;
          const harvestedArea = previous.harvested_area + ap.harvested_area;
          let prodYield = prod / harvestedArea;
          const fromYieldUnit = findYieldUnit(
            rootState.shared.units,
            ap.production_unit_id,
            ap.area_unit_id,
          );

          prodYield = rootGetters['shared/convert'](prodYield, fromYieldUnit, ap.yield_unit_id, ap.commodity_id);
          nodes[ap.commodity_id].years[year] = {
            ...previous,
            production: prod,
            seeded_area: seededArea,
            harvested_area: harvestedArea,
            prod_yield: prodYield,
            locations: [...previous.locations, ap.farm_location_id],
            // can remove farm_location_id
          };
        } else {
          nodes[ap.commodity_id].years = {
            ...nodes[ap.commodity_id].years,
            [year]: {
              ...ap,
              locations: [ap.farm_location_id],
            },
          };
        }
      });
      return nodes;
    },

    actualProductionTrees(state, getters) {
      return getActualProductionSummaryTrees(getters.preparedTableNodes, state.showChildCommodities, 'commodity_id', 'parent_commodity_id');
    },

    apFlattenTrees(state, getters) {
      // level starts at 0
      return getters.actualProductionTrees.map((tree) => flattenTree(tree, 0)).flat();
    },

    salesContractId(state) {
      return (state.contractTypes).find((ct) => ct.contract_type === 'sales')?.id;
    },

    productionContractId(state) {
      return (state.contractTypes).find((ct) => ct.contract_type === 'production')?.id;
    },

    targetContractId(state) {
      return (state.contractTypes).find((ct) => ct.contract_type === 'target')?.id;
    },

    isFarmProfileAccessible: (state, getters, rootState, rootGetters) => rootGetters['permissions/checkPermission']('farm_profile', 'read'),
  },

  mutations: {
    setSelectedYear(state, year) {
      state.selectedYear = year;
    },
    setPreferences(state, unitPreferences) {
      state.unitPreferences = unitPreferences;
    },
    setActualProductions(state, actualProductions) {
      state.actualProductions = actualProductions;
    },
    setContractTypes(state, contractTypes) {
      state.contractTypes = contractTypes;
    },
    setFarmProfileLoading(state, loading) {
      state.farmProfileLoading = loading;
    },
  },

  actions: {
    async fetchUnitPreferences({ commit, rootState }) {
      const unitPreferences = await postRequest(
        '/farm_profile/api/get_all_unit_preferences/',
      ) ?? [];

      commit('setPreferences', unitPreferences);
    },

    async fetchContractTypes({ commit, rootState }) {
      const data = await postRequest('/farm_profile/api/get_all_contract_types/');
      const values = Object.values(data);
      commit('setContractTypes', values);
    },

    async updateUnitPreference(
      {
        state, commit, rootState, rootGetters, getters, dispatch,
      },
      {
        context, unitSelected,
      },
    ) {
      // check whether unitPreferences are needed to change
      const index = state.unitPreferences.findIndex(
        (pref) => pref.context === context,
      );
      // do nothing if unit selected is the same as the current unit preference
      if (index !== -1 && state.unitPreferences[index].unit_id === unitSelected.id) {
        return;
      }
      const body = {
        context,
        unit_id: unitSelected.id,
      };
      // update unit preference in database
      await postRequest('/farm_profile/api/update_unit_preference/', body);
      await dispatch('fetchUnitPreferences');

      commit('farmProfile/inventory/initialConversion', {
        currentUnitPreference: getters.currentUnitPreference,
        convert: rootGetters['shared/convert'],
        standardUnit: rootState.shared.kgUnit,
      }, { root: true });
    },

    async fetchActualProductions({ commit, getters, rootGetters }) {
      const actualProductions = await postRequest(
        '/farm_profile/api/get_actual_productions/',
      );
      commit('setActualProductions', actualProductions);
    },

    async createActualProduction(context, {
      locationId, yearId, commodityId, seededArea,
      harvestedArea, production, areaUnit, productionUnit,
      comments,
    }) {
      try {
        const res = await postRequest(
          '/farm_profile/api/create_actual_production/',
          {
            farm_location: locationId,
            crop_year: yearId,
            commodity: commodityId,
            area_unit: areaUnit,
            production_unit: productionUnit,
            seeded_area: seededArea,
            harvested_area: harvestedArea,
            production,
            comments,
          },
        );

        this._vm.$snackbar.success(res.message);
      } catch (e) {
        this._vm.$snackbar.error(e.message);
      }
    },

    async deleteActualProduction(context, { locationId, yearId, commodity }) {
      await postRequest(
        '/farm_profile/api/delete_actual_production/',
        {
          farm_location: locationId,
          crop_year: yearId,
          commodity,
        },
      );
    },

    async updateActualProduction({ dispatch }, node) {
      try {
        const res = await postRequest('/farm_profile/api/update_actual_production/', node);

        await dispatch('fetchActualProductions');

        this._vm.$snackbar.success(res.message);
      } catch (e) {
        this._vm.$snackbar.error(e.message);
      }
    },
    async fetchSalesContractsWithoutExpectedPayoutDateByCashFlow({ commit }, payload) {
      // grabs all contracts that have no expected payout date
      // payload: {start_month: str, end_month: str}
      // res: {contracts: [Contract dictionaries]}
      const res = await postRequest('/farm_profile/api/get_sales_contracts_without_expected_payout_date_by_cash_flow/', payload);
      return res;
    },
    async fetchChangedContractsAndDeliveriesByCashFlow({ commit }, payload) {
      // grabs all contracts and deliveries that have changed since the last time they were checked
      // payload: {id: int}
      // res: {new_contracts: [Contract dictionaries],
      //       updated_contracts: [Contract dictionaries],
      //       deleted_contracts: [Contract dictionaries],
      //       contract_changes: [ContractChanges dictionaries],
      //       new_deliveries: [Delivery dictionaries],
      //       updated_deliveries: [Delivery dictionaries],
      //       deleted_deliveries: [Delivery dictionaries],
      //       delivery_changes: [DeliveryChanges dictionaries]}
      const result = await postRequest('/farm_profile/api/get_changed_contracts_and_deliveries_by_cash_flow/', payload);
      return result;
    },
  },

  modules: {
    productionFarms,
    deliveries,
    integrations,
    inventory,
    sales,
    cropPage,
    reporting,
    fileImport,
    farms,
    factors,
    salesRecommendations,
    johndeere,
  },

  namespaced: true,
};
