/* eslint-disable no-param-reassign */
import Vue from 'vue';
import { groupBy } from '@/helpers/transformations';
import { postRequest } from '@/helpers/request';
import {
  DEMO_BUDGET_PRODUCTIONS,
  DEMO_CATEGORIES,
  DEMO_PREFERENCES,
  DEMO_EXPENSES,
} from '@/components/budgeting/data/constants';

const FIXED_COMMODITY_ID = 0;

export default {
  state: () => ({
    activeCategoryID: -1,
    activeBudgetProductionID: -1,
    budgetProductions: {},
    benchmarkPrices: {},
    // a budgetProduction ID for which CropPlannerCard is expanded to show details
    categoryGroups: {},
    categories: {},
    categoryPreferences: {},
    costToggle: 1,
    expandedBudgetCard: -1,
    expenseClassifications: {},
    expenseTypes: {},
    expenses: {},
    expenseDialogOpen: false,
    expenseDialogCategory: null,
    expenseDialogInitialExpense: null,
    expenseCategoryDeleteDialogOpen: false,
    expenseCategoryFormOpen: false,
    expenseCategoryDialogOpen: false,
    isShowCategoryName: false,
    selectedExpenseCategoryId: null,
    selectedYear: null, // object
    smartPrices: {},
    selectedCommodities: [],
    recentlyCreatedCategory: null,
    importCategoryIndex: -1,
  }),

  mutations: {
    setSelectedCommodities(state, commodities) {
      state.selectedCommodities = commodities;
    },

    setActiveCategoryID(state, id) {
      state.activeCategoryID = id;
    },

    setActiveBudgetProductionID(state, id) {
      state.activeBudgetProductionID = id;
    },

    setBudgetProductions(state, budgetProductions) {
      state.budgetProductions = budgetProductions;
    },

    setBudgetProduction(state, budgetProduction) {
      Vue.set(state.budgetProductions, budgetProduction.id, budgetProduction);
    },

    setBenchmarkPrices(state, benchmarkPrices) {
      state.benchmarkPrices = benchmarkPrices;
    },

    deleteBudgetProduction(state, id) {
      Vue.delete(state.budgetProductions, id);
    },

    deleteAssociatedExpenses(state, budgetProductionID) {
      Object.entries(state.expenses).forEach(([key, value]) => {
        if (value.budgetProduction === budgetProductionID) {
          Vue.delete(state.expenses, key);
        }
      });
    },

    deleteAssociatedExpensesByCategory(state, categoryID) {
      Object.entries(state.expenses).forEach(([key, value]) => {
        if (value.category === categoryID) {
          Vue.delete(state.expenses, key);
        }
      });
    },

    setExpenses(state, expenses) {
      state.expenses = expenses;
    },

    setExpenseDialogOpen(state, value) {
      state.expenseDialogOpen = value;
    },

    setExpenseDialogCategory(state, value) {
      state.expenseDialogCategory = value;
    },

    setExpenseDialogInitialExpense(state, value) {
      state.expenseDialogInitialExpense = value;
    },

    updateExpenses(state, expenses) {
      Object.entries(expenses).forEach(([id, expense]) => {
        Vue.set(state.expenses, id, expense);
      });
    },

    updateExpenseVariable(state, data) {
      const {
        id, field, displayOnly, value,
      } = data;
      const fieldName = displayOnly ? `${field}Display` : field;
      state.expenses[id][fieldName] = value;
    },

    updateBudgetProductionVariable(state, data) {
      const {
        id, field, displayOnly, value,
      } = data;
      const fieldName = displayOnly ? `${field}Display` : field;
      state.budgetProductions[id][fieldName] = value;
    },

    setCategories(state, categories) {
      state.categories = categories;
    },

    setCategoryGroups(state, groups) {
      state.categoryGroups = groups;
    },

    setCategoryPreferences(state, preferences) {
      state.categoryPreferences = preferences;
    },

    setExpenseClassifications(state, expenseClassifications) {
      state.expenseClassifications = expenseClassifications;
    },

    setExpenseTypes(state, expenseTypes) {
      state.expenseTypes = expenseTypes;
    },

    expandBudgetCard(state, id) {
      state.expandedBudgetCard = id;
    },

    collapseBudgetCard(state) {
      state.expandedBudgetCard = -1;
    },

    deleteExpense(state, id) {
      Vue.delete(state.expenses, id);
    },

    setExpenseCategoryDialogOpen(state, value) {
      state.expenseCategoryDialogOpen = value;
    },

    setExpenseCategoryDeleteDialogOpen(state, value) {
      state.expenseCategoryDeleteDialogOpen = value;
    },

    setSelectedExpenseCategoryId(state, value) {
      state.selectedExpenseCategoryId = value;
    },

    setExpenseCategoryFormOpen(state, value) {
      state.expenseCategoryFormOpen = value;
    },

    addOrUpdateExpenseCategory(state, expenseCategory) {
      Vue.set(state.categories, expenseCategory.id, expenseCategory);
    },

    deleteExpenseCategory(state, id) {
      Vue.delete(state.categories, id);
    },

    addOrUpdatePreference(state, preference) {
      Vue.set(state.categoryPreferences, preference.id, preference);
    },

    deletePreference(state, categoryId) {
      const preferenceId = Object.values(state.categoryPreferences).find(
        (pref) => pref.category === categoryId,
      ).id;
      Vue.delete(state.categoryPreferences, preferenceId);
    },

    setSelectedYear(state, year) {
      state.selectedYear = year;
    },

    setIsShowCategoryName(state, show) {
      state.isShowCategoryName = show;
    },

    setIsCostToggle(state, costToggle) {
      state.costToggle = costToggle;
    },

    setSmartPrices(state, smartPrices) {
      state.smartPrices = smartPrices;
    },

    setRecentlyCreatedCategory(state, category) {
      state.recentlyCreatedCategory = category;
    },

    setImportCategoryIndex(state, index) {
      state.importCategoryIndex = index;
    },

  },

  getters: {
    getCommoditiesFromBudgetProductions: (state) => {
      const commodities = new Set();
      Object.values(state.budgetProductions).forEach((bp) => commodities.add(bp.commodity));
      return Array.from(commodities);
    },

    getCommoditiesAlphabetical: (state, getters, rootState) => {
      const commoditiesFromBPs = getters.getCommoditiesFromBudgetProductions;
      return commoditiesFromBPs
        .map((id) => rootState.shared.commodities[id])
        .sort((commodityA, commodityB) => commodityA.name.localeCompare(commodityB.name));
    },

    benchmarkPricesByCategoryAndCommodity: (state) => Object.values(state.benchmarkPrices)
      .reduce((previousValue, currentValue) => {
        if (!previousValue[currentValue.category]) {
          // eslint-disable-next-line no-param-reassign
          previousValue[currentValue.category] = {};
        }
        // eslint-disable-next-line no-param-reassign
        previousValue[currentValue.category][currentValue.commodity] = currentValue;
        return previousValue;
      }, {}),

    activeCategory: (state) => state.categories[state.activeCategoryID],

    activeBudgetProduction: (state) => state.budgetProductions[state.activeBudgetProductionID],

    activeCategoryTitle: (state) => `${state.categories[state.activeCategoryID]?.name ?? ''} Expenses`,

    categoriesEnabled: (state) => {
      const preferred = Object.values(state.categoryPreferences)
        .filter((p) => p.preferred);

      const enabledCategoryIds = preferred
        .map((pref) => pref.category);

      return Object.values(state.categories)
        .filter((category) => enabledCategoryIds.includes(category.id));
    },

    categoriesVariable: (state, getters) => getters.categoriesEnabled
      .filter((category) => category.type === getters.variableExpenseID)
      .sort((catA, catB) => (catA.name.localeCompare(catB.name))),

    categoriesFixed: (state, getters) => getters.categoriesEnabled
      .filter((category) => category.type === getters.fixedExpenseID)
      .sort((catA, catB) => (catA.name.localeCompare(catB.name))),

    expenses: (state, getters) => {
      const expenses = {};
      Object.entries(state.expenses).forEach(([id, expense]) => {
        // Filter out expenses from disabled categories
        // TODO: Refactor expenses getter UT-782
        const categoryIds = getters.categoriesEnabled.map((c) => c.id);
        if (categoryIds.includes(expense.category)) {
          let totalCost = 0;
          let unitCost = expense.amount;

          if (expense.type === getters.variableExpenseID) {
            const { acres } = state.budgetProductions[expense.budgetProduction];
            totalCost = expense.amount * expense.allocationPercentage * acres;
          }

          if (expense.type === getters.fixedExpenseID) {
            const farmLocationId = expense.budgetProduction; // TODO: UT-999

            // IF (regular fixed cost) ELSE (benchmark fixed cost)
            if (expense.price_unit === 'dollar') {
              // Fixed fixed expense
              totalCost = expense.amount;
              unitCost = expense.amount / getters.totalAcresPerFarm[farmLocationId];
            } else {
              // Variable fixed expense
              // Assuming we still save the bennchmark fixed expense with the unit cost in the
              // amount field, we can just compute the total as if it were a variable expense.
              totalCost = expense.amount * getters.totalAcresPerFarm[farmLocationId];
            }
          }

          expenses[id] = {
            ...expense,
            totalCost,
            unitCost,
          };
        }
      });
      return expenses;
    },

    expensesByType: (state, getters) => groupBy(Object.values(getters.expenses), 'type'),

    expensesByCategory: (state, getters) => groupBy(Object.values(getters.expenses), 'category'),

    expensesByBudgetProduction: (state, getters) => {
      // Group by crops that have some expenses
      const combinedExpenses = groupBy(
        Object.values(getters.expenses)
          .filter((expense) => expense.type === getters.variableExpenseID),
        'budgetProduction',
      );

      // Add the remaining crops that have no expenses
      Object.keys(state.budgetProductions).forEach((budgetProduction) => {
        if (budgetProduction in combinedExpenses) return;
        combinedExpenses[budgetProduction] = [];
      });

      // Backend returns farmLocation.id in the place of expense.budgetProduction for
      // for fixed expenses because of GFK.
      // This adds the fixed expenses into each budget production and replaces the
      // farmLocation.id with the budgetProduction.id
      Object.entries(getters.budgetProductionsByFarmLocation)
        .forEach(([farmLocation, budgetProductions]) => {
          const fixedExpenses = Object.values(getters.expenses)
            .filter((expense) => expense.type === getters.fixedExpenseID
              && expense.budgetProduction === Number(farmLocation));

          budgetProductions.forEach((budgetProduction) => {
            combinedExpenses[budgetProduction.id]
              .push(...fixedExpenses
                .map((expense) => {
                  const { totalCost } = expense;
                  const { acres } = budgetProduction;
                  const ratio = acres / getters.totalAcresPerFarm[farmLocation];
                  return {
                    ...expense,
                    budgetProduction: budgetProduction.id,
                    totalCost: totalCost * ratio,
                  };
                }));
          });
        });

      return combinedExpenses;
    },

    hasFixedExpenses: (state, getters) => Object.values(getters.expenses)
      .filter((expense) => expense.type === getters.fixedExpenseID).length > 0,

    variableExpensesByBudgetProduction: (state, getters) => Object
      .entries(getters.expensesByBudgetProduction)
      .reduce((acc, [budgetProduction, expenses]) => {
        acc[budgetProduction] = expenses.filter((e) => e.type === getters.variableExpenseID);
        return acc;
      }, {}),

    fixedExpensesByBudgetProduction: (state, getters) => Object
      .entries(getters.expensesByBudgetProduction)
      .reduce((acc, [budgetProduction, expenses]) => {
        acc[budgetProduction] = expenses.filter((e) => e.type === getters.fixedExpenseID);
        return acc;
      }, {}),

    variableExpenseID: (state) => Object.values(state.expenseTypes)
      .find((type) => type.name.toLowerCase() === 'variable')?.id,

    fixedExpenseID: (state) => Object.values(state.expenseTypes)
      .find((type) => type.name.toLowerCase() === 'fixed')?.id,

    operatingID: (state) => Object.values(state.expenseClassifications)
      .find((classification) => classification.name.toLowerCase() === 'operating')?.id,

    interestID: (state) => Object.values(state.expenseClassifications)
      .find((classification) => classification.name.toLowerCase() === 'interest')?.id,

    depreciationID: (state) => Object.values(state.expenseClassifications)
      .find((classification) => classification.name.toLowerCase() === 'depreciation')?.id,

    totalAcres: (state) => Object.values(state.budgetProductions)
      .reduce((acc, current) => acc + current.acres, 0),

    totalAcresPerFarm: (state) => Object.values(state.budgetProductions)
      .reduce((acc, current) => {
        if (!Object.prototype.hasOwnProperty.call(acc, current.farm_location)) {
          acc[current.farm_location] = 0;
        }
        acc[current.farm_location] += current.acres;
        return acc;
      }, {}),

    getCategoryName: (state) => (id) => state.categories[id]?.name ?? '',

    getCommodityName: (state) => (id) => state.budgetProductions[id]?.name ?? '',

    getCommodityId: (state) => (id) => state.budgetProductions[id].commodity ?? '',

    getCategoryExpenses: (state, getters) => (id) => {
      const categoryExpenses = getters.expensesByCategory[id] ?? [];
      const category = getters.categoriesEnabled.find((cat) => cat.id === id);

      return categoryExpenses.map((expense) => ({
        ...expense,
        country: category.country,
      }));
    },

    totalExpenseByCategory: (state, getters) => {
      const result = {};
      Object.keys(state.categories).forEach((id) => {
        const expenses = getters.expensesByCategory[id];
        if (!expenses) {
          result[id] = 0;
          return;
        }

        // Calculate the total expneses
        result[id] = Object.values(expenses).reduce(
          (sum, expense) => sum + expense.totalCost,
          0,
        );
      });
      return result;
    },

    getIntermediateValuesByBudgetProduction: (state, getters) => {
      const defaultCost = () => ({
        unitCostSum: 0,
        totalCostSum: 0,
      });

      const budgetProductions = Object.values(state.budgetProductions).reduce((acc, current) => {
        const key = current.id;
        if (!Object.prototype.hasOwnProperty.call(acc, key)) {
          acc[key] = {
            id: key,
            [getters.operatingID]: { ...defaultCost() },
            [getters.interestID]: { ...defaultCost() },
            [getters.depreciationID]: { ...defaultCost() },
            unitCostSum: 0,
            totalCostSum: 0,
            revenue: 0,
          };
        }
        return acc;
      }, {});

      Object.values(budgetProductions).forEach((bp) => {
        const { id } = bp;
        const variableExpenses = getters.variableExpensesByBudgetProduction[id];
        const fixedExpenses = getters.fixedExpensesByBudgetProduction[id];
        bp.revenue = state.budgetProductions[id].yield
          * state.budgetProductions[id].price * state.budgetProductions[id].acres;

        variableExpenses.forEach((e) => {
          const expenseClassificationID = state.categories[e.category].classification;
          bp[expenseClassificationID].unitCostSum += e.unitCost;
          bp[expenseClassificationID].totalCostSum += e.totalCost;
          bp.unitCostSum += e.unitCost;
          bp.totalCostSum += e.totalCost;
        });

        fixedExpenses.forEach((e) => {
          const expenseClassificationID = state.categories[e.category].classification;
          bp[expenseClassificationID].unitCostSum += e.unitCost;
          bp[expenseClassificationID].totalCostSum += e.totalCost;
          bp.unitCostSum += e.unitCost;
          bp.totalCostSum += e.totalCost;
        });
      });

      return budgetProductions;
    },

    chartDataByBudgetProduction: (state, getters) => {
      const result = {};
      Object.values(state.budgetProductions).forEach((bp) => {
        const { id } = bp;
        const variableExpenses = getters.variableExpensesByBudgetProduction[id];
        const fixedExpenses = getters.fixedExpensesByBudgetProduction[id];
        const dataSources = {
          'newchart-json-base': {
            id: 'newchart-json-base',
            chart: {
              caption: 'Expense Summary',
            },
            data: [],
          },
        };
        Object.entries(groupBy([...variableExpenses, ...fixedExpenses], 'category'))
          .forEach(([categoryId, expenses]) => {
            const baseDataSource = dataSources['newchart-json-base'];
            const categoryName = getters.getCategoryName(categoryId);
            const groupId = state.categories[categoryId].group;
            const groupName = state.categoryGroups[groupId].name;
            const linkId = `newchart-json-group${groupId}`;
            let group = baseDataSource.data.find((g) => g.link === linkId);

            // Initialize group/category objects
            if (!group) {
              group = {
                label: `<b>${groupName}</b>`,
                value: 0,
                link: linkId,
              };
              baseDataSource.data.push(group);
              dataSources[linkId] = {
                id: linkId,
                chart: {
                  caption: groupName,
                },
                data: [],
              };
            }

            // Calculate total category expense amount
            const total = Object.values(expenses).reduce(
              (sum, expense) => sum + expense.totalCost,
              0,
            );

            // Add category total to group total
            group.value += total;

            // Add category linked data
            dataSources[linkId].data.push({
              label: `<b>${categoryName}</b>`,
              value: total,
            });
          });

        // Sort data
        Object.values(dataSources).forEach((dataSource) => {
          dataSource.data.sort((a, b) => b.value - a.value);
        });

        result[id] = {
          id,
          chartDataSources: dataSources,
        };
      });

      return result;
    },

    budgetProductionsByFarmLocation: (state) => groupBy(Object.values(state.budgetProductions), 'farm_location'),

    getAnalysisValuesByBudgetProduction: (state, getters) => {
      const intermediateValues = getters.getIntermediateValuesByBudgetProduction;
      const defaultValues = () => ({
        unitCost: 0,
        totalCost: 0,
        unitRevenue: 0,
        unitRevenueNet: 0,
        ROI: null,
        breakEvenYield: null,
        breakEvenPrice: null,
      });

      const analysisValues = Object.values(intermediateValues).reduce((acc, current) => {
        const key = current.id; // key is a budgetProduction ID
        if (!Object.prototype.hasOwnProperty.call(acc, key)) {
          acc[key] = {
            id: key,
            ...defaultValues(),
          };
        }

        const { yield: productionYield, price } = state.budgetProductions[key];

        acc[key].unitCost = current.unitCostSum;
        acc[key].totalCost = current.totalCostSum;

        acc[key].unitRevenue = productionYield * price;
        acc[key].unitRevenueNet = acc[key].unitRevenue - acc[key].unitCost;

        const hasExpenses = (
          getters.fixedExpensesByBudgetProduction[key].length
          || getters.variableExpensesByBudgetProduction[key].length
        );
        if (hasExpenses) {
          acc[key].ROI = acc[key].unitRevenueNet / acc[key].unitCost;
          acc[key].breakEvenYield = acc[key].unitCost / price;
          acc[key].breakEvenPrice = acc[key].unitCost / productionYield;
        }

        return acc;
      }, {});

      return analysisValues;
    },

    getOverviewTotalsByFarmLocation: (state, getters) => {
      const defaultValues = () => ({
        totalCost: 0,
        totalRevenue: 0,
        totalRevenueNet: 0,
        totalAcres: 0,
      });

      const roiByFarmLocation = Object.entries(getters.budgetProductionsByFarmLocation)
        .reduce((acc, current) => {
          const [farmLocationId, budgetProductions] = current;
          const key = farmLocationId;
          if (!Object.prototype.hasOwnProperty.call(acc, key)) {
            acc[key] = {
              id: key,
              ...defaultValues(),
            };
          }

          budgetProductions.forEach((bp) => {
            const { id } = bp;
            const { yield: productionYield, price, acres } = state.budgetProductions[id];
            const intermediateValues = getters.getIntermediateValuesByBudgetProduction[id];

            acc[key].totalCost += intermediateValues.totalCostSum;
            acc[key].totalRevenue += productionYield * price * acres;
            acc[key].totalAcres += acres;
          });

          return acc;
        }, {});

      Object.values(roiByFarmLocation).forEach((f) => {
        f.totalRevenueNet = f.totalRevenue - f.totalCost;
      });

      return roiByFarmLocation;
    },

    getOverviewTotals: (state, getters) => {
      const totalValues = Object.values(getters.getOverviewTotalsByFarmLocation)
        .reduce((acc, current) => {
          acc.totalCost += current.totalCost;
          acc.totalRevenue += current.totalRevenue;
          acc.totalRevenueNet += current.totalRevenueNet;
          acc.totalAcres += current.totalAcres;

          return acc;
        }, {
          totalCost: 0,
          totalRevenue: 0, // aka Total Gross (UI)
          totalRevenueNet: 0, // aka Total Net Revenue (UI)
          totalAcres: 0, // aka Total Acres (UI)
          ROI: null, // aka Total ROI (UI)
        });

      if (getters.hasExpenses) {
        totalValues.ROI = totalValues.totalRevenueNet / totalValues.totalCost;
      }

      return totalValues;
    },

    expenseDialogMode: (state) => (state.expenseDialogInitialExpense ? 'Edit' : 'Create'),

    hasBudgetProductions: (state) => Object.keys(state.budgetProductions).length !== 0,

    hasExpenses: (state, getters) => Object.keys(getters.expenses).length !== 0,

    // Filtered by preference

    getBenchmarkCategoriesEnabled: (_, getters) => (Object.values(getters.categoriesEnabled)
      .filter((category) => category.isBenchmark)),

    hasBenchmarkCategoriesEnabled: (_, getters) => Object
      .keys(getters.getBenchmarkCategoriesEnabled).length > 0,

    getDefaultCategoriesEnabled: (_, getters) => (Object.values(getters.categoriesEnabled)
      .filter((category) => !category.isBenchmark && !category.isCustom)),

    hasDefaultCategoriesEnabled: (_, getters) => Object
      .keys(getters.getDefaultCategoriesEnabled).length > 0,

    getCustomCategoriesEnabled: (_, getters) => (Object.values(getters.categoriesEnabled)
      .filter((category) => category.isCustom)),

    hasCustomCategoriesEnabled: (_, getters) => Object
      .keys(getters.getCustomCategoriesEnabled).length > 0,

    // Not filtered by preference

    getBenchmarkCategories: (state) => (Object.values(state.categories)
      .filter((category) => category.isBenchmark)),

    hasBenchmarkCategories: (_, getters) => Object
      .keys(getters.getBenchmarkCategories).length > 0,

    getDefaultCategories: (state) => (Object.values(state.categories)
      .filter((category) => !category.isBenchmark && !category.isCustom)),

    hasDefaultCategories: (_, getters) => Object
      .keys(getters.getDefaultCategories).length > 0,

    getCustomCategories: (state) => (Object.values(state.categories)
      .filter((category) => category.isCustom)),

    hasCustomCategories: (_, getters) => Object
      .keys(getters.getCustomCategories).length > 0,

    // Whether there are benchmark expenses for the given commodity id
    hasBenchmarkExpenses: (state, getters) => (selectedCommodityId) => {
      if (!getters.benchmarkPricesByCategoryAndCommodity || !selectedCommodityId) return false;
      const categories = getters.getBenchmarkCategories;
      // For each category that has benchmark prices, check if the currently
      // selected commodity has benchmark prices
      // Or if the category is fixed (commodity id would be 0)
      return categories.map((c) => c.id).some((categoryId) => {
        const benchmarkPricesForCategory = getters
          .benchmarkPricesByCategoryAndCommodity[categoryId];

        return benchmarkPricesForCategory
          && (selectedCommodityId in benchmarkPricesForCategory
            || FIXED_COMMODITY_ID in benchmarkPricesForCategory);
      });
    },

    getIntermediateValuesByCommodity: (state, getters) => Object
      .values(getters.getIntermediateValuesByBudgetProduction)
      .reduce((acc, bp) => {
        const bpId = bp.id;
        const { commodity } = state.budgetProductions[bpId];
        if (!Object.prototype.hasOwnProperty.call(acc, commodity)) {
          acc[commodity] = {
            totalGross: 0,
            operatingCosts: 0,
            interestCosts: 0,
            depreciationCosts: 0,
            totalCost: 0,
            netIncome: 0,
          };
        }

        acc[commodity].totalGross += bp.revenue;
        acc[commodity].operatingCosts += bp[getters.operatingID].totalCostSum;
        acc[commodity].interestCosts += bp[getters.interestID].totalCostSum;
        acc[commodity].depreciationCosts += bp[getters.depreciationID].totalCostSum;
        acc[commodity].totalCost += bp.totalCostSum;
        acc[commodity].netIncome += bp.revenue - bp.totalCostSum;
        return acc;
      }, {}),

    getExpenseRatiosByCommodity: (state, getters) => Object
      .entries(getters.getIntermediateValuesByCommodity)
      .reduce((acc, [key, commodityValues]) => {
        acc[key] = {
          operatingRatio: commodityValues.operatingCosts / commodityValues.totalGross,
          interestRatio: commodityValues.interestCosts / commodityValues.totalGross,
          depreciationRatio: commodityValues.depreciationCosts / commodityValues.totalGross,
          netRatio: commodityValues.netIncome / commodityValues.totalGross,
        };

        return acc;
      }, {}),
    isROIAccessible: (state, getters, rootState, rootGetters) => rootGetters['permissions/checkPermission']('ROI_calculator', 'read'),
  },

  actions: {
    selectCategory({ commit }, value) {
      commit('setActiveCategoryID', value);
    },

    async loadBudgetProductions({ commit }, budgetData) {
      let data = budgetData;
      const abortController = data?.abortController;

      if (abortController) {
        data = data.data;
      }

      const budgetProductions = await API.getBudgetProductions(data, abortController);
      commit('setBudgetProductions', budgetProductions);
    },

    async loadBenchmarkPrices({ commit }, budgetData) {
      let data = budgetData;
      const abortController = data?.abortController;

      if (abortController) {
        data = data.data;
      }

      const benchmarkPrices = await API.getBenchmarkPrices(data, abortController);
      commit('setBenchmarkPrices', benchmarkPrices);
    },

    async loadExpenses({ commit }, budgetData) {
      let data = budgetData;
      const abortController = data?.abortController;

      if (abortController) {
        data = data.data;
      }

      const expenses = await API.getExpenses(data, abortController);
      commit('setExpenses', expenses);
    },

    async createExpenses({ commit }, payload) {
      const expenses = await API.createExpenses(payload);
      commit('updateExpenses', expenses);
    },

    async updateExpense({ commit }, payload) {
      const expense = await API.updateExpense(payload);
      const { id } = expense;
      const expenses = { [id]: expense };
      commit('updateExpenses', expenses);
    },

    async resetBudgetFarmLocation({ state, commit }) {
      // Only reset budgetProductions if there's some existing data
      if (Object.keys(state.budgetProductions).length > 0) {
        commit('setBudgetProductions', {});
      }

      // Only reset expenses if there's some existing data
      if (Object.keys(state.expenses).length > 0) {
        commit('setExpenses', {});
      }
    },

    async getRoiStaticData({ commit }) {
      const data = await API.getRoiStaticData();
      commit('setCategoryGroups', data.category_groups);
      commit('setExpenseTypes', data.expense_types);
      commit('setExpenseClassifications', data.expense_classifications);
    },

    async fetchCategoriesPreferences({ commit, rootState }, abortController) {
      const farmLocations = rootState.farmLocations.selectedFarmLocations ?? [];
      const data = await API.getBudgetCategoriesPreferences({
        farm_locations: farmLocations,
      }, abortController);
      commit('setCategories', data.categories);
      commit('setCategoryPreferences', data.preferences);
    },

    openExpenseDialog({ state, commit }, payload) {
      const {
        category, expense, show, costToggle,
      } = payload;
      commit('setExpenseDialogCategory', state.categories[category]);
      commit('setExpenseDialogInitialExpense', expense);
      commit('setExpenseDialogOpen', true);
      commit('setIsShowCategoryName', show);
      commit('setIsCostToggle', costToggle);
    },

    async deleteExpense({ commit }, id) {
      try {
        await API.deleteExpense(id);
        commit('deleteExpense', id);
        this._vm.$snackbar.success('Successfully deleted expense');
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },

    async deleteBudgetProduction({ commit }, id) {
      try {
        await postRequest('/budgeting/api/delete_budget_production/', { id });
        commit('deleteBudgetProduction', id);
        commit('deleteAssociatedExpenses', id);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },

    async createExpenseCategory({ commit, state }, payload) {
      try {
        const data = await postRequest(
          '/budgeting/api/create_expense_category/',
          payload,
        );
        commit('addOrUpdateExpenseCategory', data.category);
        commit('setExpenseCategoryFormOpen', false);
        commit('addOrUpdatePreference', data.preference);
        commit('setRecentlyCreatedCategory', data.category);
        this._vm.$snackbar.success('Successfully created expense category');
        if (state.expenseDialogOpen) {
          commit('setExpenseDialogCategory', data.category);
          commit('setIsShowCategoryName', true);
        }
      } catch (e) {
        this._vm.$snackbar.error(e);
        throw new Error('An error occured while creating expense category');
      }
    },

    async updateExpenseCategory({ state, commit }, payload) {
      try {
        const data = await postRequest(
          '/budgeting/api/update_expense_category/',
          payload,
        );
        if (state.categories[payload.id].type !== payload.type) {
          commit('deleteAssociatedExpensesByCategory', payload.id);
        }
        commit('addOrUpdateExpenseCategory', data);
        commit('setExpenseCategoryFormOpen', false);
        commit('setSelectedExpenseCategoryId', null);
        this._vm.$snackbar.success('Successfully updated expense category');
      } catch (e) {
        this._vm.$snackbar.error(e);
        throw new Error('An error occured while updating expense category');
      }
    },

    async deleteExpenseCategory({ commit }, id) {
      try {
        await postRequest('/budgeting/api/delete_expense_category/', { id });
        commit('deleteExpenseCategory', id);
        commit('deleteAssociatedExpensesByCategory', id);
        commit('deletePreference', id);
        this._vm.$snackbar.success('Successfully deleted expense category');
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },

    async setExpenseCategoryPreferences(_, payload) {
      try {
        await postRequest(
          '/budgeting/api/set_category_preferences/',
          payload,
        );
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },

    async fetchSmartPricesByCropYear({ commit }, payload) {
      try {
        const data = await API.getSmartPricesByCropYear(payload);
        commit('setSmartPrices', data);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },
    async updateBudgetProductions({ commit }, payload) {
      try {
        const data = await API.updateBudgetProductions(payload);
        data.forEach((budgetProduction) => {
          commit('setBudgetProduction', budgetProduction);
        });
        return data;
      } catch (e) {
        this._vm.$snackbar.error(e);
        return null;
      }
    },

    setDemoValues({ commit }) {
      commit('setBudgetProductions', DEMO_BUDGET_PRODUCTIONS);
      commit('setCategories', DEMO_CATEGORIES);
      commit('setCategoryPreferences', DEMO_PREFERENCES);
      commit('setExpenses', DEMO_EXPENSES);
    },

    async handleRoiCalculatorImport({ commit }, payload) {
      try {
        const { data, cropYear } = payload;
        await API.handleRoiCalculatorImport(data);
        commit('setSelectedYear', cropYear);
        this._vm.$snackbar.success('Successfully imported data');
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },
  },
  namespaced: true,
};
