<template>
  <g-dialog
    v-model="dialog"
    :title="title"
    :action-buttons="actions"
    height="600"
    @cancel="onCancel"
  >
    <AddonSelector
      v-if="shouldRender"
      ref="selector"
      v-model="value"
      :default-publication="computedDefaultPublication"
      :enforce-default="enforceDefault"
      :annual-billing="annualBilling"
      :only-addons="onlyAddons"
      :show-expand-analysis="showExpandAnalysis"
      :show-addon-prices="true"
      :allow-edit-added="allowEditAdded"
      :include-plan-price="includePlanPrice"
      :plan-id="addonTarget"
      :use-us-plans="isAmerican"
      :show-coupon-disclaimer="showCouponDisclaimer"
    />
  </g-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import GDialog from '../generic/GDialog.vue';
import AddonSelector from './AddonSelector.vue';
import { formatCurrency } from '../../helpers/formatting';
import { SMART_PLAN } from './data/constants';

export default {
  name: 'AddonDialog',
  components: {
    GDialog,
    AddonSelector,
  },

  props: {
    annualBilling: {
      type: Boolean,
      default: true,
    },
    showCouponDisclaimer: {
      type: Boolean,
      default: true,
    },
    onlyAddons: {
      type: Boolean,
      default: true,
    },
    showExpandAnalysis: {
      type: Boolean,
      default: false,
    },
    show: {
      type: Boolean,
      default: false,
    },
    defaultPublication: {
      type: String,
      default: null,
    },
    enforceDefault: {
      type: Boolean,
      default: false,
    },
    allowEditAdded: {
      type: Boolean,
      default: true,
    },
    includePlanPrice: {
      type: Boolean,
      default: true,
    },
    value: {
      type: Object,
      default: null,
    },
    addonTarget: {
      type: String,
      default: SMART_PLAN,
    },
  },

  data() {
    return {
      actions: [{
        name: 'CANCEL',
        type: 'default',
        side: 'right',
        onClick: this.onCancel,
      }, {
        name: 'CONTINUE TO PAYMENT',
        type: 'primary',
        side: 'right',
        mustValidate: true,
        onClick: this.onContinue,
      }],
      shouldRender: true,
    };
  },

  computed: {
    ...mapState('subscription', ['province']),
    ...mapState('chargebee', ['useCustomAddonPrices']),
    ...mapGetters('subscription', ['isAmerican']),
    frequency() {
      return this.annualBilling ? 'year' : 'month';
    },
    title() {
      return this.isAmerican ? 'Plan Summary & Add-Ons' : 'Publication Options';
    },
    dialog: {
      get() {
        return this.show;
      },
      set() {},
    },
    computedDefaultPublication() {
      if (this.province && Object.keys(this.province).length > 0) {
        return this.defaultPublication;
      }
      return null;
    },
  },

  watch: {
    show(val) {
      if (val) {
        this.shouldRender = false;
        this.$nextTick(() => {
          this.shouldRender = true;
        });
      }
    },
  },

  methods: {
    formatCurrency,
    onCancel() {
      this.$emit('cancel');
    },
    onContinue() {
      let { selectedAddons } = this.$refs.selector;
      if (this.useCustomAddonPrices) {
        // If we need to use custom addon prices,
        //  we need to get the selected addons as objects with prices from the selector
        selectedAddons = this.$refs.selector.selectedAddonObjects;
      }
      this.$emit(
        'continue',
        selectedAddons,
        this.$refs.selector.defaultPub,
        this.$refs.selector.diffAddons,
        this.$refs.selector.selectedProvince,
        this.$refs.selector.currency,
      );
    },
  },
};
</script>
