import {
  ANNUAL,
  BASIC_PLAN,
  FRIENDLY_ITEM_NAMES,
  SMART_PLAN_PLUS,
  MARKET_INTELLIGENCE_PLAN,
  MONTHLY,
  QUARTERLY,
  SEMIANNUAL,
  SMART_PLAN,
  COMMERCIAL_BASIC_PLAN,
  COMMERCIAL_PLAN,
  FARMLINK_ADDON,
  DEPUTTER_ADDON,
  ADVISOR_PLAN,
  TELUS_BASIC_PLAN,
  TELUS_MARKET_INTELLIGENCE_PLAN,
  TELUS_SMART_PLAN,
  TELUS_SMART_PLAN_PLUS,
  SYNGENTA_BASIC_EMO,
  SYNGENTA_BASIC_GMP,
  SYNGENTA_BASIC_MAS,
  GARS_GMP,
  JEREMYANDRES_GMP,
  KEATINGSEEDS_GMP,
  NADEAU_GMP,
  FOUR_PECKS_GMP,
  US_BASIC_PLAN,
  US_MARKET_INTELLIGENCE_PLAN,
  US_COMMERCIAL_BASIC_PLAN,
  US_COMMERCIAL_PLAN,
  WEEKLY_RESEARCH_ADDON,
  STARTER_PLAN,
  COMMERCIAL_STARTER_PLAN,
  INDIVIDUAL_CONTENT_ADDONS,
  LEGACY_AGRIPARTNERS_AG_NEWS,
  CFFO_BASIC_EMO,
} from '@/components/payments/data/constants';
import {
  getIdParts,
} from '@/components/payments/data/helpers';
import { postRequest } from '@/helpers/request';
import { datadogLogs } from '@datadog/browser-logs';

export default {
  state: () => ({
    customer: null,
    subscription: null,
    items: null,
    pricing: {},
    itemPriceList: [],
    useCustomAddonPrices: false,
    planLevels: {
      [BASIC_PLAN]: 0,
      [MARKET_INTELLIGENCE_PLAN]: 2,
      [SMART_PLAN]: 3,
      [SMART_PLAN_PLUS]: 4,
      [TELUS_BASIC_PLAN]: 10,
      [TELUS_MARKET_INTELLIGENCE_PLAN]: 11,
      [TELUS_SMART_PLAN]: 12,
      [TELUS_SMART_PLAN_PLUS]: 13,
      [COMMERCIAL_BASIC_PLAN]: 102,
      [COMMERCIAL_PLAN]: 103,
      [ADVISOR_PLAN]: 1001,
      [SYNGENTA_BASIC_EMO]: 0,
      [SYNGENTA_BASIC_GMP]: 0,
      [SYNGENTA_BASIC_MAS]: 0,
      [GARS_GMP]: 0,
      [JEREMYANDRES_GMP]: 0,
      [KEATINGSEEDS_GMP]: 0,
      [NADEAU_GMP]: 0,
      [FOUR_PECKS_GMP]: 0,
      [US_BASIC_PLAN]: 0,
      [US_MARKET_INTELLIGENCE_PLAN]: 2,
      [US_COMMERCIAL_BASIC_PLAN]: 201,
      [US_COMMERCIAL_PLAN]: 202,
      [STARTER_PLAN]: 1,
      [COMMERCIAL_STARTER_PLAN]: 101,
      [LEGACY_AGRIPARTNERS_AG_NEWS]: 0,
      [CFFO_BASIC_EMO]: 0,
    },

    commercialPlans: [
      COMMERCIAL_BASIC_PLAN,
      COMMERCIAL_PLAN,
      US_COMMERCIAL_BASIC_PLAN,
      US_COMMERCIAL_PLAN,
      COMMERCIAL_STARTER_PLAN,
    ],

    smartPlans: [
      SMART_PLAN,
      TELUS_SMART_PLAN,
    ],

    marketIntelligencePlans: [
      MARKET_INTELLIGENCE_PLAN,
      TELUS_MARKET_INTELLIGENCE_PLAN,
      US_MARKET_INTELLIGENCE_PLAN,
    ],

    /**
     * Plans which can be switched to out of a trial
     */
    trialExitPlans: [
      SMART_PLAN,
      MARKET_INTELLIGENCE_PLAN,
      TELUS_SMART_PLAN,
      TELUS_MARKET_INTELLIGENCE_PLAN,
      COMMERCIAL_BASIC_PLAN,
      COMMERCIAL_PLAN,
      US_MARKET_INTELLIGENCE_PLAN,
      US_COMMERCIAL_BASIC_PLAN,
      US_COMMERCIAL_PLAN,
    ],

    basicPlans: [
      BASIC_PLAN,
      TELUS_BASIC_PLAN,
      COMMERCIAL_BASIC_PLAN,
      SYNGENTA_BASIC_EMO,
      SYNGENTA_BASIC_GMP,
      SYNGENTA_BASIC_MAS,
      GARS_GMP,
      JEREMYANDRES_GMP,
      KEATINGSEEDS_GMP,
      NADEAU_GMP,
      FOUR_PECKS_GMP,
      US_BASIC_PLAN,
      US_COMMERCIAL_BASIC_PLAN,
      LEGACY_AGRIPARTNERS_AG_NEWS,
      STARTER_PLAN,
      COMMERCIAL_STARTER_PLAN,
      CFFO_BASIC_EMO,
    ],

    /**
     * Matrix of actions to take to move from one plan to another.
     * Options: noop, cancel, downgrade, frequency, self-serve, sales, contact.
     *
     * Access pattern: matrix[source][destination]
     * Overrides exist for frequencies, i.e. matrix[source][sourceFrequency][destination] or matrix[source][sourceFrequency][destination][destinationFrequency]
     * Note: matrix[source][destination][destinationFrequency] is not valid.
     *
     * The object returned can be an action or {trial: action, default: action}, in case the trial status of the source is relevant.
     *
     * Prefer never to access this object directly, and instead use the provided getPlanPath getter.
     *
     * This matrix is based off of https://grainfox.atlassian.net/wiki/spaces/GH/pages/1281130501/Chargebee+Upgrade+paths
     */
    planChangeMatrix: {
      // Canadian Producer plans
      [BASIC_PLAN]: {
        monthly: {
          [BASIC_PLAN]: {
            yearly: 'frequency',
          },
        },
        yearly: {
          [BASIC_PLAN]: {
            monthly: 'frequency',
          },
        },
        [BASIC_PLAN]: 'noop',
        [MARKET_INTELLIGENCE_PLAN]: {
          monthly: 'css',
          yearly: 'self-serve',
        },
        [SMART_PLAN]: 'self-serve',
        [SMART_PLAN_PLUS]: 'sales',
      },
      [MARKET_INTELLIGENCE_PLAN]: {
        monthly: {
          [MARKET_INTELLIGENCE_PLAN]: {
            yearly: {
              trial: 'self-serve',
              default: 'frequency',
            },
            monthly: {
              trial: 'css',
              default: 'noop',
            },
          },
        },
        yearly: {
          [MARKET_INTELLIGENCE_PLAN]: {
            monthly: 'css',
          },
        },
        [BASIC_PLAN]: 'cancel',
        [MARKET_INTELLIGENCE_PLAN]: {
          trial: 'self-serve',
          default: 'noop',
        },
        [SMART_PLAN]: 'self-serve',
        [SMART_PLAN_PLUS]: 'sales',
      },
      [SMART_PLAN]: {
        monthly: {
          [SMART_PLAN]: {
            yearly: {
              trial: 'self-serve',
              default: 'frequency',
            },
          },
        },
        yearly: {
          [SMART_PLAN]: {
            monthly: {
              trial: 'self-serve',
              default: 'frequency',
            },
          },
        },
        [BASIC_PLAN]: 'cancel',
        [MARKET_INTELLIGENCE_PLAN]: {
          monthly: 'css',
          yearly: {
            trial: 'self-serve',
            default: 'downgrade',
          },
        },
        [SMART_PLAN]: {
          trial: 'self-serve',
          default: 'noop',
        },
        [SMART_PLAN_PLUS]: 'sales',
      },
      [SMART_PLAN_PLUS]: {
        monthly: {
          [SMART_PLAN_PLUS]: {
            yearly: 'frequency',
          },
          [MARKET_INTELLIGENCE_PLAN]: {
            monthly: 'css',
          },
        },
        yearly: {
          [SMART_PLAN_PLUS]: {
            monthly: 'frequency',
          },
          [MARKET_INTELLIGENCE_PLAN]: {
            monthly: 'css',
          },
        },
        [BASIC_PLAN]: 'cancel',
        [MARKET_INTELLIGENCE_PLAN]: 'downgrade',
        [SMART_PLAN]: 'downgrade',
        [SMART_PLAN_PLUS]: 'noop',
      },
      // American Producer plans
      [US_BASIC_PLAN]: {
        monthly: {
          [US_BASIC_PLAN]: {
            yearly: 'frequency',
          },
        },
        yearly: {
          [US_BASIC_PLAN]: {
            monthly: 'frequency',
          },
        },
        [US_BASIC_PLAN]: 'noop',
        [US_MARKET_INTELLIGENCE_PLAN]: {
          monthly: 'css',
          yearly: 'self-serve',
        },
      },
      [US_MARKET_INTELLIGENCE_PLAN]: {
        monthly: {
          [US_MARKET_INTELLIGENCE_PLAN]: {
            yearly: {
              trial: 'self-serve',
              default: 'frequency',
            },
            monthly: {
              trial: 'css',
              default: 'noop',
            },
          },
        },
        yearly: {
          [US_MARKET_INTELLIGENCE_PLAN]: {
            monthly: 'css',
          },
        },
        [US_BASIC_PLAN]: 'cancel',
        [US_MARKET_INTELLIGENCE_PLAN]: {
          trial: 'self-serve',
          default: 'noop',
        },
      },
      [LEGACY_AGRIPARTNERS_AG_NEWS]: {
        [US_BASIC_PLAN]: 'noop',
        [US_MARKET_INTELLIGENCE_PLAN]: {
          yearly: 'self-serve',
        },
      },
      // Telus plans
      [TELUS_BASIC_PLAN]: {
        monthly: {
          [TELUS_BASIC_PLAN]: {
            yearly: 'frequency',
          },
        },
        yearly: {
          [TELUS_BASIC_PLAN]: {
            monthly: 'frequency',
          },
        },
        [TELUS_BASIC_PLAN]: 'noop',
        [TELUS_MARKET_INTELLIGENCE_PLAN]: {
          monthly: 'css',
          yearly: 'self-serve',
        },
        [TELUS_SMART_PLAN]: 'self-serve',
        [TELUS_SMART_PLAN_PLUS]: 'sales',
      },
      [TELUS_MARKET_INTELLIGENCE_PLAN]: {
        monthly: {
          [TELUS_MARKET_INTELLIGENCE_PLAN]: {
            yearly: {
              trial: 'self-serve',
              default: 'frequency',
            },
            monthly: {
              trial: 'css',
              default: 'noop',
            },
          },
        },
        yearly: {
          [TELUS_MARKET_INTELLIGENCE_PLAN]: {
            monthly: 'css',
          },
        },
        [TELUS_BASIC_PLAN]: 'cancel',
        [TELUS_MARKET_INTELLIGENCE_PLAN]: {
          trial: 'self-serve',
          default: 'noop',
        },
        [TELUS_SMART_PLAN]: 'self-serve',
        [TELUS_SMART_PLAN_PLUS]: 'sales',
      },
      [TELUS_SMART_PLAN]: {
        monthly: {
          [TELUS_SMART_PLAN]: {
            yearly: {
              trial: 'self-serve',
              default: 'frequency',
            },
          },
        },
        yearly: {
          [TELUS_SMART_PLAN]: {
            monthly: {
              trial: 'self-serve',
              default: 'frequency',
            },
          },
        },
        [TELUS_BASIC_PLAN]: 'cancel',
        [TELUS_MARKET_INTELLIGENCE_PLAN]: {
          monthly: 'css',
          yearly: {
            trial: 'self-serve',
            default: 'downgrade',
          },
        },
        [TELUS_SMART_PLAN]: {
          trial: 'self-serve',
          default: 'noop',
        },
        [TELUS_SMART_PLAN_PLUS]: 'sales',
      },
      [TELUS_SMART_PLAN_PLUS]: {
        monthly: {
          [TELUS_SMART_PLAN_PLUS]: {
            yearly: 'frequency',
          },
          [TELUS_MARKET_INTELLIGENCE_PLAN]: {
            monthly: 'css',
          },
        },
        yearly: {
          [TELUS_SMART_PLAN_PLUS]: {
            monthly: 'frequency',
          },
          [TELUS_MARKET_INTELLIGENCE_PLAN]: {
            monthly: 'css',
          },
        },
        [TELUS_BASIC_PLAN]: 'cancel',
        [TELUS_MARKET_INTELLIGENCE_PLAN]: 'downgrade',
        [TELUS_SMART_PLAN]: 'downgrade',
        [TELUS_SMART_PLAN_PLUS]: 'noop',
      },
      // Syngenta plans
      [SYNGENTA_BASIC_EMO]: {
        [BASIC_PLAN]: 'noop',
        [MARKET_INTELLIGENCE_PLAN]: {
          monthly: 'css',
          yearly: 'self-serve',
        },
        [SMART_PLAN]: 'self-serve',
        [SMART_PLAN_PLUS]: 'sales',
      },
      [SYNGENTA_BASIC_MAS]: {
        [BASIC_PLAN]: 'noop',
        [MARKET_INTELLIGENCE_PLAN]: {
          monthly: 'css',
          yearly: 'self-serve',
        },
        [SMART_PLAN]: 'self-serve',
        [SMART_PLAN_PLUS]: 'sales',
      },
      [SYNGENTA_BASIC_GMP]: {
        [BASIC_PLAN]: 'noop',
        [MARKET_INTELLIGENCE_PLAN]: {
          monthly: 'css',
          yearly: 'self-serve',
        },
        [SMART_PLAN]: 'self-serve',
        [SMART_PLAN_PLUS]: 'sales',
      },
      [GARS_GMP]: {
        [BASIC_PLAN]: 'noop',
        [MARKET_INTELLIGENCE_PLAN]: {
          monthly: 'css',
          yearly: 'self-serve',
        },
        [SMART_PLAN]: 'self-serve',
        [SMART_PLAN_PLUS]: 'sales',
      },
      [JEREMYANDRES_GMP]: {
        [BASIC_PLAN]: 'noop',
        [MARKET_INTELLIGENCE_PLAN]: {
          monthly: 'css',
          yearly: 'self-serve',
        },
        [SMART_PLAN]: 'self-serve',
        [SMART_PLAN_PLUS]: 'sales',
      },
      [KEATINGSEEDS_GMP]: {
        [BASIC_PLAN]: 'noop',
        [MARKET_INTELLIGENCE_PLAN]: {
          monthly: 'css',
          yearly: 'self-serve',
        },
        [SMART_PLAN]: 'self-serve',
        [SMART_PLAN_PLUS]: 'sales',
      },
      [NADEAU_GMP]: {
        [BASIC_PLAN]: 'noop',
        [MARKET_INTELLIGENCE_PLAN]: {
          monthly: 'css',
          yearly: 'self-serve',
        },
        [SMART_PLAN]: 'self-serve',
        [SMART_PLAN_PLUS]: 'sales',
      },
      [FOUR_PECKS_GMP]: {
        [BASIC_PLAN]: 'noop',
        [MARKET_INTELLIGENCE_PLAN]: {
          monthly: 'css',
          yearly: 'self-serve',
        },
        [SMART_PLAN]: 'self-serve',
        [SMART_PLAN_PLUS]: 'sales',
      },
      [STARTER_PLAN]: {
        [BASIC_PLAN]: 'cancel',
        [MARKET_INTELLIGENCE_PLAN]: {
          monthly: 'css',
          yearly: 'self-serve',
        },
        [SMART_PLAN]: 'self-serve',
        [SMART_PLAN_PLUS]: 'sales',
        [US_BASIC_PLAN]: 'cancel',
        [US_MARKET_INTELLIGENCE_PLAN]: {
          yearly: 'self-serve',
        },
      },
      // Canadian Commercial plans
      // Commercial Starter Plan is common both for Canada and US
      [COMMERCIAL_STARTER_PLAN]: {
        [COMMERCIAL_BASIC_PLAN]: 'cancel',
        [COMMERCIAL_PLAN]: 'sales',
        [US_COMMERCIAL_BASIC_PLAN]: 'cancel',
        [US_COMMERCIAL_PLAN]: 'sales',
      },
      [COMMERCIAL_BASIC_PLAN]: {
        monthly: {
          [COMMERCIAL_BASIC_PLAN]: {
            yearly: 'frequency',
          },
        },
        yearly: {
          [COMMERCIAL_BASIC_PLAN]: {
            monthly: 'frequency',
          },
        },
        [COMMERCIAL_BASIC_PLAN]: 'noop',
        [COMMERCIAL_PLAN]: 'sales',
      },
      [COMMERCIAL_PLAN]: {
        monthly: {
          [COMMERCIAL_PLAN]: {
            yearly: 'frequency',
          },
        },
        yearly: {
          [COMMERCIAL_PLAN]: {
            monthly: 'frequency',
          },
        },
        [COMMERCIAL_BASIC_PLAN]: 'cancel',
        [COMMERCIAL_PLAN]: {
          trial: 'sales',
          default: 'noop',
        },
      },
      // American Commerical Plans
      [US_COMMERCIAL_BASIC_PLAN]: {
        monthly: {
          [US_COMMERCIAL_BASIC_PLAN]: {
            yearly: 'frequency',
          },
        },
        yearly: {
          [US_COMMERCIAL_BASIC_PLAN]: {
            monthly: 'frequency',
          },
        },
        [US_COMMERCIAL_BASIC_PLAN]: 'noop',
        [US_COMMERCIAL_PLAN]: 'sales',
      },
      [US_COMMERCIAL_PLAN]: {
        monthly: {
          [US_COMMERCIAL_PLAN]: {
            yearly: 'frequency',
          },
        },
        yearly: {
          [US_COMMERCIAL_PLAN]: {
            monthly: 'frequency',
          },
        },
        [US_COMMERCIAL_BASIC_PLAN]: 'cancel',
        [US_COMMERCIAL_PLAN]: {
          trial: 'sales',
          default: 'noop',
        },
      },
      [CFFO_BASIC_EMO]: {
        [BASIC_PLAN]: 'noop',
        [MARKET_INTELLIGENCE_PLAN]: {
          monthly: 'css',
          yearly: 'self-serve',
        },
        [SMART_PLAN]: 'self-serve',
        [SMART_PLAN_PLUS]: 'sales',
      },
    },
    addonWhitelist: {
      [BASIC_PLAN]: [],
      [MARKET_INTELLIGENCE_PLAN]: [DEPUTTER_ADDON, FARMLINK_ADDON],
      [SMART_PLAN]: [DEPUTTER_ADDON, FARMLINK_ADDON],
      [SMART_PLAN_PLUS]: [],
      [COMMERCIAL_PLAN]: [],
      [COMMERCIAL_BASIC_PLAN]: [],
      [TELUS_BASIC_PLAN]: [],
      [TELUS_MARKET_INTELLIGENCE_PLAN]: [DEPUTTER_ADDON, FARMLINK_ADDON],
      [TELUS_SMART_PLAN]: [DEPUTTER_ADDON, FARMLINK_ADDON],
      [TELUS_SMART_PLAN_PLUS]: [],
      [US_BASIC_PLAN]: [],
      [US_MARKET_INTELLIGENCE_PLAN]: [WEEKLY_RESEARCH_ADDON],
      [US_COMMERCIAL_BASIC_PLAN]: [],
      [US_COMMERCIAL_PLAN]: [],
      [STARTER_PLAN]: INDIVIDUAL_CONTENT_ADDONS,
      [COMMERCIAL_STARTER_PLAN]: INDIVIDUAL_CONTENT_ADDONS,
    },
    discountAmount: 0.10,
    availableAddons: [],
  }),

  getters: {
    getPlanPath(state) {
      /**
       * Return the action that should be taken to move from source to destination.
       *
       * @param {object} source The source plan
       * @param {string} source.plan The source plan id
       * @param {boolean} source.isMonthly The source plan frequency
       * @param {object} destination The destination plan
       * @param {string} destination.plan The destination plan id
       * @param {boolean} destination.isMonthly The destination plan frequency
       * @param {boolean} inTrial Whether the user is in trial
       *
       * @returns {'noop' | 'frequency' | 'self-serve' | 'cancel' | 'downgrade' | 'contact' | 'sales' | undefined} The action to take
       */
      return (source, destination, inTrial = false) => {
        if (!source || !destination) {
          return undefined;
        }
        const destDictionary = state.planChangeMatrix[source.plan];
        if (!destDictionary) {
          return undefined;
        }

        /**
         * Determines whether the value is returnable
         * @param {unknown} value The value to check
         *
         * @returns {false | 'noop' | 'frequency' | 'self-serve' | 'cancel' | 'downgrade' | 'contact' | 'sales'} The value to return
         */
        function isFinal(value) {
          if (typeof value === 'string') {
            return value;
          }
          if (typeof value !== 'object') {
            return false;
          }
          if ('trial' in value) {
            return inTrial ? value.trial : value.default;
          }
          return false;
        }

        let returnValue = isFinal(destDictionary);
        if (returnValue) {
          return returnValue;
        }

        const srcFreqKey = source.isMonthly ? 'monthly' : 'yearly';
        const destFreqKey = destination.isMonthly ? 'monthly' : 'yearly';

        if (srcFreqKey in destDictionary) {
          let value = destDictionary[srcFreqKey];
          returnValue = isFinal(value);
          if (returnValue) {
            return returnValue;
          }
          if (destFreqKey in value) {
            value = value[destFreqKey];
            returnValue = isFinal(value);
            if (returnValue) {
              return returnValue;
            }
          }
          if (destination.plan in value) {
            value = value[destination.plan];
            returnValue = isFinal(value);
            if (returnValue) {
              return returnValue;
            }
            if (destFreqKey in value) {
              value = value[destFreqKey];
              returnValue = isFinal(value);
              if (returnValue) {
                return returnValue;
              }
            }
          }
        }
        if (destination.plan in destDictionary) {
          let value = destDictionary[destination.plan];
          returnValue = isFinal(value);
          if (returnValue) {
            return returnValue;
          }
          if (destFreqKey in value) {
            value = value[destFreqKey];
            returnValue = isFinal(value);
            if (returnValue) {
              return returnValue;
            }
          }
        }
        return undefined;
      };
    },

    subscriptionId(state) {
      if (state.subscription) {
        return state.subscription.id;
      }
      return null;
    },

    calculateCost(state, getters) {
      const de = 1 + state.discountAmount;
      return (value, options) => {
        const { frequency } = getters.plan;
        const planDuration = getters.frequencyToNumber[frequency];
        const {
          isMonthly,
          applyDiscount,
        } = {
          isMonthly: false,
          applyDiscount: false,
          ...options,
        };
        if (!applyDiscount) {
          return isMonthly ? value * planDuration : value / planDuration;
        }
        if (isMonthly) {
          return (value / de) * planDuration;
        }
        return (value * de) / planDuration;
      };
    },

    isInTrial(state) {
      if (!state.subscription || !state.subscription.trial_end || !state.subscription.trial_end) {
        return false;
      }
      const now = Date.now() / 1000;
      return state.subscription.trial_start <= now && state.subscription.trial_end >= now;
    },

    trialLengthInDays(state, getters) {
      if (!getters.isInTrial) {
        return null;
      }
      const start = state.subscription.trial_start;
      const end = state.subscription.trial_end;
      return Math.round((end - start) / (60 * 60 * 24));
    },

    remainingDaysInTrial(state, getters) {
      if (!getters.isInTrial) {
        return null;
      }
      const now = Date.now() / 1000;
      const end = state.subscription.trial_end;
      return Math.round((end - now) / (60 * 60 * 24));
    },

    hasCard(state) {
      return state.customer && state.customer.card_status !== 'no_card';
    },

    plan(state) {
      if (!state.items) {
        return undefined;
      }
      return Object.values(state.items).filter((item) => item.is_plan).map((plan) => {
        const parts = getIdParts(plan.id);

        // TG-685 We may want to put more thought into handling
        // nonstandard plans that don't match the above regex,
        // but for now, this will keep errors from cascading.
        if (!parts) return {};

        const { id, frequency } = parts;
        const level = state.planLevels[id] || 0;
        return {
          ...plan,
          level,
          frequency,
          planId: id,
        };
      })[0];
    },

    /**
     * TODO: TG-685 Refactors
     * Will be useful for calculations in a future refactor.
     */
    frequencyToNumber() {
      return {
        [MONTHLY]: 1,
        [QUARTERLY]: 3,
        [SEMIANNUAL]: 6,
        [ANNUAL]: 12,
      };
    },

    planFrequency(state, getters) {
      if (!getters.plan) {
        return 1;
      }
      const { frequency } = getters.plan;
      return getters.frequencyToNumber[frequency];
    },

    planLevel(state, getters) {
      return getters.plan?.level || 0;
    },

    isBasic(state, getters) {
      return !getters.plan || state.basicPlans.includes(getters.plan.planId);
    },

    /**
     * If the current plan is commercial or commercial basic
     */
    isCommercial(state, getters) {
      return !!getters.plan && state.commercialPlans.includes(getters.plan?.planId);
    },

    isCommercialBasic(state, getters) {
      return getters.isBasic && getters.isCommercial;
    },

    isMarketIntelligence(state, getters) {
      return !!getters.plan && state.marketIntelligencePlans.includes(getters.plan.planId);
    },

    isSmart(state, getters) {
      return !!getters.plan && state.smartPlans.includes(getters.plan.planId);
    },

    addons(state) {
      return Object.values(state.items).filter((x) => !x.is_plan);
    },

    toTrimmedId() {
      return (id) => {
        const { id: trimmed } = getIdParts(id) ?? { id: null };
        return trimmed;
      };
    },

    toFriendlyName(state, getters) {
      return (id) => {
        const idTrimmed = getters.toTrimmedId(id);
        return FRIENDLY_ITEM_NAMES[idTrimmed] || idTrimmed || id;
      };
    },

    isMonthly(state, getters) {
      return Boolean(getters?.plan?.is_monthly);
    },

    defaultAddon(state, getters) {
      const res = getters.addons.filter((x) => x.is_default);
      return (res.length > 0) ? res[0] : null;
    },

    hasAddon(state, getters) {
      return (id) => getters.addons.filter((x) => x.id.indexOf(id) === 0).length > 0;
    },

    showUpgradeButton(state, getters, rootState, rootGetters) {
      return rootGetters['user/isAuthenticated']
        && getters.plan
        && (getters.isBasic || rootGetters['subscription/isDeputter'] || !getters.hasCard)
        && rootGetters['permissions/isOwnerOrBilling']('update');
    },

    normalize(state, getters) {
      /**
       * Normalize a price from one frequency to another
       * @param {number} price The price to normalize
       * @param {string} frequency The frequency of the price
       * @param {string | false} [destinationFrequency] The frequency to normalize to
       * @returns {number} The normalized price
       */
      return (price, frequency, destinationFrequency) => {
        if (!destinationFrequency || frequency === destinationFrequency) {
          return price;
        }
        const from = getters.frequencyToNumber[frequency];
        const to = getters.frequencyToNumber[destinationFrequency];
        if (!from || !to) {
          // from and to are never 0, so this is strictly a nullish check
          return price;
        }
        return price * (to / from);
      };
    },

    getPrice(state, getters) {
      /**
       * Get the price of a given plan or addon from pricing object
       * @param {string|{id: string, frequency: string, currency: string}} input The id of the plan or addon
       * @param {object} options
       * @param {'dollars'|'cents'} [options.type] The type of price to return
       * @param {number} [options.default] The default price to return if the plan or addon is not found
       * @param {false|string} [options.normalize] The frequency to normalize the price to, or false to not normalize
       * @returns {number} price in cents or dollars
       */
      return (input, options = {
        type: 'dollars',
        default: 0,
        normalize: false,
      }) => {
        const expanded = typeof input === 'string' ? getIdParts(input) : input;
        if (!expanded) {
          return null;
        }
        const {
          id, currency, frequency,
        } = expanded;
        if (!id || !currency || !frequency) {
          return null;
        }
        const price = state.pricing[id]?.[currency]?.[frequency];
        if (!price) {
          return options.default ?? 0;
        }
        const type = options.type ?? 'dollars';
        let value;
        switch (type) {
          case 'cents':
            value = price.cents;
            break;
          case 'dollars':
          default:
            value = price.value;
            break;
        }
        return getters.normalize(value, frequency, options.normalize);
      };
    },
  },

  mutations: {
    setCustomer(state, payload) {
      state.customer = payload;
    },

    setSubscription(state, payload) {
      state.subscription = payload;
    },

    setItems(state, payload) {
      state.items = payload;
    },

    setUseCustomAddonPrices(state, payload) {
      state.useCustomAddonPrices = payload;
    },

    setAvailableAddons(state, payload) {
      state.availableAddons = payload;
    },

    setPrices(state, payload) {
      state.itemPriceList = payload;
      const newValue = {};
      payload.forEach((itemPrice) => {
        const parts = getIdParts(itemPrice.id);
        if (!parts) {
          return;
        }
        const { id, currency, frequency } = parts;
        newValue[id] = newValue[id] || {};
        newValue[id][currency] = newValue[id][currency] || {};
        const value = itemPrice.price / 100;
        newValue[id][currency][frequency] = {
          value,
          cents: itemPrice.price,
        };
      });
      state.pricing = newValue;
    },
  },

  actions: {
    loadChargebeeState({ commit }, payload) {
      if (!payload) {
        return;
      }

      const {
        customer,
        subscription,
        items,
      } = payload;

      commit('setCustomer', customer);
      commit('setSubscription', subscription);
      commit('setItems', items);
    },

    /**
     * Handle a Chargebee webhook
     * @param {object} context
     * @param {object} payload The incoming webhook data
     * @param {string} payload.event_type The type of webhook
     * @param {boolean} payload.updated_addons Whether addons could have been updated
     * @param {number[]} payload.sub_ids The list of affected subscriptions
     * @param {number[]} payload.user_ids The list of affected users
     * @param {object} payload.customer_data The incoming customer data
     */
    handleChargebeeWebhook({ dispatch, rootState }, payload) {
      const {
        sub_ids,
        customer_data,
      } = payload;
      const currentSubscriptionId = rootState.subscription.id;
      if (sub_ids.includes(currentSubscriptionId)) {
        dispatch('loadChargebeeState', customer_data);
      }
    },

    async getAllItemPrices({ commit }) {
      try {
        const res = await postRequest('/commerce/get_all_item_prices/');
        commit('setPrices', res);
      } catch (e) {
        datadogLogs.logger.error('Error in getAllItemPrices', {}, e);
      }
    },

    async getAllAddons({ commit }) {
      try {
        const res = await postRequest('/commerce/get_all_addons/');
        commit('setAvailableAddons', res);
      } catch (e) {
        datadogLogs.logger.error('Error in getAllAddons', {}, e);
      }
    },

    async changeAddons({ _ }, payload) {
      try {
        await postRequest('/commerce/change_addons/', payload);
        this._vm.$snackbar.success('Addons updated successfully');
      } catch (error) {
        this._vm.$snackbar.error(error);
      }
    },
  },

  namespaced: true,
};
