<template>
  <v-list expand>
    <template
      v-for="item in activatedLinks"
    >
      <template v-if="item.items && item.items.length > 0">
        <v-list-group
          v-if="!item.disabled"
          :ref="`group-${item.title}`"
          :key="item.title"
          :data-qa="`navigation-links-${item.title.toLowerCase().replace(' ', '-')}-item`"
          :disabled="item.disabled"
          :value="item.open"
          no-action
          color=""
          :class="{'v-list-item--route-active': item.active}"
          @click="handleClickGroup(item.title)"
        >
          <template #activator>
            <v-list-item-icon v-if="item.icon">
              <v-icon color="primary">
                {{ item.icon }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="font-weight-medium primary-grey--text">
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </template>

          <template v-for="subItem in item.items">
            <v-list-item
              v-if="!subItem.items"
              :key="subItem.title"
              :to="subItem.routerPath && activeLink && activeLink.routerPath ? subItem.path : null"
              :href="subItem.routerPath && activeLink && activeLink.routerPath ? null : subItem.path"
              :disabled="subItem.disabled"
              :class="{'v-list-item--route-active': subItem.active}"
            >
              <v-list-item-content>
                <v-list-item-title class="font-weight-medium primary-grey--text">
                  {{ subItem.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-group
              v-else
              :key="subItem.title"
              :disabled="subItem.disabled"
              :value="subItem.open"
              no-action
              color=""
              class="ml-10"
              :class="{'v-list-item--route-active': subItem.active}"
            >
              <template #activator>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-medium primary-grey--text">
                    {{ subItem.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
              <template v-for="subSubItem in subItem.items">
                <v-list-item
                  :key="subSubItem.title"
                  :to="subSubItem.routerPath && activeLink && activeLink.routerPath ? subSubItem.path : null"
                  :href="subSubItem.routerPath && activeLink && activeLink.routerPath ? null : subSubItem.path"
                  :disabled="subSubItem.disabled"
                  class="ml-4"
                  :class="{'v-list-item--route-active': subSubItem.active}"
                >
                  <v-list-item-title>{{ subSubItem.title }}</v-list-item-title>
                </v-list-item>
              </template>
            </v-list-group>
          </template>
        </v-list-group>
      </template>

      <template v-else-if="!item.items">
        <v-list-item
          :key="item.title"
          :data-qa="`navigation-links-${item.title.toLowerCase().replace(' ', '-')}-item`"
          :to="item.routerPath && activeLink && activeLink.routerPath ? item.path : null"
          :href="item.routerPath && activeLink && activeLink.routerPath ? null : item.path"
          :disabled="item.disabled"
          :class="{'v-list-item--route-active': item.active}"
        >
          <v-list-item-icon v-if="item.icon">
            <v-icon color="primary">
              {{ item.icon }}
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title
              class="p-rel overflow-visible
              d-flex justify-space-between
              font-weight-medium primary-grey--text"
            >
              <span>{{ item.title }}</span>
              <simple-counter
                v-if="item.counter && getCounterValue(item.counter) > 0"
                :count="getCounterValue(item.counter)"
              />
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </template>
  </v-list>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import SimpleCounter from '../generic/SimpleCounter.vue';

export default {
  components: {
    SimpleCounter,
  },

  props: {
    items: {
      type: Array,
      required: true,
    },
  },

  computed: {
    ...mapState('launchDarkly', ['flags']),

    filteredLinks() {
      return this.items
        .filter((link) => (link.disabled !== true)
        && (this.stagingOnly(link.stagingOnly))
        && (this.showOnlyPC(link.showOnlyPC))
        && (this.showToOwner(link.isOwner))
        && (this.showToGMAUserOnSubscriptionOnly(link.isSubscriptionGMA))
        && (this.showToGMA(link.isGMA))
        && (this.showToFarmlinkGMA(link.isFarmlinkGMA))
        && (this.ldFlagIsOn(link.ldFlagKey))
        && (this.showSyngenta(link.showSyngenta))
        && (this.showCommercial(link.showCommercial))
        && (this.showCommercialOnly(link.showCommercialOnly))
        && (this.isUS(link.isUS)))
        .filter((link) => {
          const allowBadPayments = (link.allowBadPayments === true);
          const permissionFunction = allowBadPayments ? this.checkPermissionAllowBadPayment
            : this.checkPermission;

          if (link.permissionGrouping) {
            const permissions = link.permission.map(
              ({ scope, permission }) => permissionFunction(scope, permission),
            );
            if (link.permissionGrouping === 'or') {
              return permissions.some((x) => x);
            }
            if (link.permissionGrouping === 'and') {
              return permissions.every((x) => x);
            }
            return false;
          }

          return link.permission == null
            || permissionFunction(
              link.permission.scope,
              link.permission.permission,
            );
        });
    },

    availableLinks() {
      return this.filteredLinks
        .map((link) => {
          if (link.isParent) {
            // eslint-disable-next-line no-param-reassign
            link.items = this.filteredLinks
              .filter((child) => child.parent && child.parent === link.title);
          }
          return link;
        })
        .filter((link) => !link.parent);
    },

    activatedLinks() {
      const links = [];
      const availableLinks = JSON.parse(JSON.stringify(this.availableLinks));
      let currRoute = this.$route.fullPath;

      // Enforce trailing slash
      if (currRoute[currRoute.length - 1] !== '/') {
        currRoute += '/';
      }

      availableLinks.forEach((link) => {
        let linkUrl = link.path;
        if (linkUrl && linkUrl[linkUrl.length - 1] !== '/') {
          linkUrl += '/';
        }
        if (link.items) {
          link.items.forEach((childLink) => {
            let childUrl = childLink?.path;
            if (childUrl && childUrl[childUrl.length - 1] !== '/') {
              childUrl += '/';
            }
            if (childLink.items) {
              childLink.items.forEach((subChildLink) => {
                let subChildUrl = subChildLink?.path;
                if (subChildUrl && subChildUrl[subChildUrl.length - 1] !== '/') {
                  subChildUrl += '/';
                }
                if (currRoute.includes(subChildUrl)) {
                  // eslint-disable-next-line no-param-reassign
                  childLink.active = true;
                  // eslint-disable-next-line no-param-reassign
                  childLink.open = true;
                  // eslint-disable-next-line no-param-reassign
                  link.open = true;
                  // eslint-disable-next-line no-param-reassign
                  subChildLink.active = true;
                }
              });
            }
            if (currRoute.includes(childUrl)) {
              // eslint-disable-next-line no-param-reassign
              link.active = true;
              // eslint-disable-next-line no-param-reassign
              link.open = true;
              // eslint-disable-next-line no-param-reassign
              childLink.active = true;
            }
          });
        } else if (currRoute === linkUrl) {
          // eslint-disable-next-line no-param-reassign
          link.active = true;
        }
        links.push(link);
      });
      return links;
    },

    activeLink() {
      const link = this.activatedLinks.find((l) => l.active);
      if (link && link.isParent) {
        return link.items.find((child) => child.active);
      }
      return link;
    },
    ...mapState('launchDarkly', ['flags']),
    ...mapState('app', ['isMobileDevice', 'isStaging', 'isDebug']),
    ...mapState('user', ['isEarlyAccess', 'isInternalUser', 'isFocusGroup', 'isSubscriptionGMA', 'isFarmlinkGMA', 'isGMA']),
    ...mapGetters('permissions', ['checkPermission', 'checkPermissionAllowBadPayment', 'isOwner']),
    ...mapGetters('subscription', ['getIsFarmer', 'isSyngenta', 'isAmerican']),
    ...mapGetters('chargebee', ['isCommercial']),
  },

  methods: {
    showOnlyPC(show) {
      return !show || (show && !this.$isMobile);
    },

    stagingOnly(show) {
      return !show || (show && (this.isStaging || this.isDebug));
    },

    restrictedRelease(restrictedRelease) {
      if (restrictedRelease) {
        const { earlyAccess, internalUser, focusGroup } = restrictedRelease;
        return (earlyAccess && this.isEarlyAccess) || (internalUser && this.isInternalUser)
            || (focusGroup && this.isFocusGroup);
      }
      return true;
    },

    getCounterValue(counter) {
      const [store, field] = counter.split('/');
      return this.$store.state[store][field];
    },

    ldFlagIsOn(flagKey) {
      // Default is to show link if no feature flag exists
      return this.flags[flagKey] ?? true;
    },

    showToOwner(isOwner) {
      return !isOwner || this.isOwner;
    },

    showSyngenta(showSyngenta) {
      return !(this.isSyngenta && showSyngenta === false);
    },

    showCommercial(showCommercial) {
      return !(this.isCommercial && showCommercial === false);
    },

    showCommercialOnly(showCommercialOnly) {
      return (this.isCommercial && showCommercialOnly) || !showCommercialOnly || this.isInternalUser;
    },

    showToFarmlinkGMA(isFarmlinkGMA) {
      return !isFarmlinkGMA
        || (this.isFarmlinkGMA && isFarmlinkGMA);
    },

    showToGMA(isUserGMA) {
      return !isUserGMA || (this.isGMA && isUserGMA);
    },

    showToGMAUserOnSubscriptionOnly(isSubscriptionGMA) {
      return !isSubscriptionGMA || (this.isSubscriptionGMA && isSubscriptionGMA);
    },

    handleClickGroup(key) {
      const [component] = this.$refs[`group-${key}`];
      this.$emit('expand', component);
    },
    isUS(americanLink) {
      if (americanLink) {
        return this.isAmerican;
      }
      if (americanLink === false) {
        return !this.isAmerican;
      }
      return true;
    },
  },
};
</script>
