<template>
  <div
    class="grey lighten-5 rounded-xl"
    :style="`width: ${width}px;`"
  >
    <div class="grey lighten-3 pl-4 py-2 rounded-t-xl">
      <span
        class="primary-grey--text font-weight-bold text-body-2"
      >
        GrainFox Analysts’ Recommendation
      </span>
    </div>
    <div
      class="d-flex flex-column max-w ma-4 mt-n1"
    >
      <template>
        <div
          class="ml-1 mb-6"
          :class="$isMobile ? 'pt-4' : 'pt-16'"
        >
          <div class="primary-grey--text">
            <span
              class="text-h4"
              :class="{
                'text-h6 font-weight-regular': $isMobile,
              }"
            >
              Move to
              <span class="positive-color">{{ recommendation.total }}%</span>
              sold on {{ cropYear.long_name }}
              <span
                class="positive-color"
              >{{ crop }}</span>
            </span>
          </div>
        </div>
        <div
          class="d-flex flex-wrap justify-center"
          :class="$isMobile?'flex-column':''"
        >
          <v-btn
            class="primary-grey--text text-wrap text-button-bold mx-4 mb-4"
            :style="!$isMobile?'width: 300px;':''"
            :href="resourceHubLink"
          >
            PREVIOUS RECOMMENDATIONS
          </v-btn>
          <v-btn
            class="primary text-button-bold text-wrap"
            :style="!$isMobile?'width: 300px;':''"
            :href="priceMapLink"
          >
            <v-icon small>
              mdi-currency-usd
            </v-icon>
            FIND PRICES NEAR YOU
          </v-btn>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'SalesRecommendation',
  props: {
    recommendation: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    cropYear: {
      type: Object,
      required: true,
    },
    commodity: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showMostLikelyPrice: false,
    };
  },

  inject: [
    '$containerWidth',
  ],
  computed: {
    ...mapState('analysisDashboard', ['priceMapLinks', 'libraryLinks']),

    crop() {
      return this.commodity.display_name;
    },

    libraryReference() {
      return this.libraryLinks?.[this.cropYear.id]?.[this.commodity.id];
    },

    slug() {
      const reference = this.libraryReference?.latest_article;
      if (reference) {
        return `/dashboard/resource_hub/${reference}`;
      }
      return '#';
    },

    priceMapLink() {
      const url = new URL(
        '/bids/map',
        window.location.origin,
      );
      url.searchParams.set('crop', this.commodity.display_name);
      url.searchParams.set('commodity', this.commodity.name);
      const reference = this.priceMapLinks?.[this.commodity.id];
      if (reference) {
        url.searchParams.set('crop', reference.crop);
        url.searchParams.set('commodity', reference.commodity);
      }
      url.searchParams.set('sr', true);
      return url.toString();
    },

    resourceHubLink() {
      const url = new URL(
        '/dashboard/resource_hub',
        window.location.origin,
      );
      const reference = this.libraryReference?.topic;
      if (reference) {
        url.searchParams.set('topics', reference);
      }
      url.searchParams.set('publications', 'Sales Recommendations');
      return url.toString();
    },

    width() {
      return this.$containerWidth();
    },
  },
};
</script>

  <style scoped lang="scss">
  @use "@/colors/colors";

  .positive-color {
    color: colors.$positive;
  }
  ::v-deep .v-progress-linear {
    border-radius: 40px;
  }
  ::v-deep .v-progress-linear__determinate {
    border-radius: 40px;
  }
  ::v-deep .v-progress-linear__background {
    left: 0 !important;
    width: 100% !important;
  }
  </style>
