<template>
  <aside
    class="slide-drawer slide-drawer--right rounded-xl rounded-r-0 d-flex"
    :class="drawerClasses"
    :style="drawerStyles"
  >
    <!-- Minimize -->
    <SlideDrawerPeek
      v-show="showToggle"
      :value="minimize"
      :width="peekWidth"
      @click="handleClickToggle"
    />

    <div class="d-flex flex-column flex-1 py-10 pr-10 overflow-hidden">
      <!-- Header -->
      <SlideDrawerHeader
        :title="title"
        :subtitle="subtitle"
        :info="info"
        :permanent="permanent"
        :class="{'pl-8':!showToggle}"
        @close="handleClickClose"
      >
        <template
          v-if="this.$slots.subtitle"
          #subtitle
        >
          <slot name="subtitle" />
        </template>
        <template
          v-if="this.$slots.info"
          #info
        >
          <slot name="info" />
        </template>
      </SlideDrawerHeader>

      <!-- Content -->
      <div class="flex-grow-1 overflow-y-auto">
        <slot />
      </div>
    </div>
  </aside>
</template>

<script>
import SlideDrawerPeek from './SlideDrawerPeek.vue';
import SlideDrawerHeader from './SlideDrawerHeader.vue';

export default {
  name: 'SlideDrawerRight',

  components: {
    SlideDrawerPeek,
    SlideDrawerHeader,
  },

  props: {
    value: {
      default: undefined,
      validator(value) {
        return typeof value === 'boolean' || typeof value === 'undefined';
      },
    },

    title: {
      type: String,
      default: '',
    },

    subtitle: {
      type: String,
      default: '',
    },

    info: {
      type: String,
      default: '',
    },

    permanent: {
      type: Boolean,
      default: false,
    },

    minimize: {
      type: Boolean,
      default: false,
    },

    width: {
      type: Number,
      default: 520,
    },

    bottom: {
      type: Boolean,
      default: false,
    },

    nudgeTop: {
      type: Number,
      default: 0,
    },

    nudgeBottom: {
      type: Number,
      default: 0,
    },

    drawerClasses: {
      type: Object,
      required: true,
    },

    isOpen: {
      type: Boolean,
      required: true,
    },

    showToggle: {
      type: Boolean,
      default: true,
    },

    peekWidth: {
      type: Number,
      default: 64,
    },

    fitContent: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    drawerStyles() {
      const padding = 16;
      const defaultTop = this.$headerOffset + padding;
      const defaultBottom = padding;
      return {
        top: `${defaultTop + this.nudgeTop}px`,
        bottom: `${defaultBottom + this.nudgeBottom}px`,
        'max-width': `${this.width}px`,
      };
    },
  },

  methods: {
    handleClickToggle() {
      this.$emit('toggle-minimize');
    },

    handleClickClose() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
.slide-drawer--right {
  position: fixed;
  right: 0;
  left: auto;
  width: 100%;
  transform: translateX(0%);
  box-shadow: 0px 8px 16px rgba(181, 153, 133, 0.12); // TODO: UT-928 Elevation 3
  z-index: 4;

  &.slide-drawer--minimize {
    transform: translateX(calc(100% - 64px));
  }

  &.slide-drawer--close {
    transform: translateX(calc(100% + 32px));
  }
}
</style>
