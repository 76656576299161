import { postRequest } from '@/helpers/request';
import colourPalette from '@/config/palette';

export default {
  namespaced: true,
  state: () => ({
    advancedAnalysisCommodities: {},
    advancedAnalysisCommoditiesWithMappings: [],
    salesRecommendations: [],
    smartPricesForYear: [],
    salesRecommendationsForYear: [],
    libraryLinks: {},
    priceMapLinks: {},
    smartPriceRegions: [],
  }),
  mutations: {
    setAdvancedAnalysisCommodities(state, advancedAnalysisCommodities) {
      state.advancedAnalysisCommodities = advancedAnalysisCommodities || {};
    },
    setAdvancedAnalysisCommoditiesWithMappings(state, advancedAnalysisCommodities) {
      state.advancedAnalysisCommoditiesWithMappings = advancedAnalysisCommodities || {};
    },
    setSalesRecommendations(state, salesRecommendations) {
      state.salesRecommendations = salesRecommendations || [];
    },
    setLibraryLinks(state, libraryLinks) {
      state.libraryLinks = libraryLinks || {};
    },
    setPriceMapLinks(state, priceMapLinks) {
      state.priceMapLinks = priceMapLinks || {};
    },
    setSmartPriceRegions(state, value) {
      state.smartPriceRegions = value;
    },
    setSmartPricesForYear(state, value) {
      state.smartPricesForYear = value;
    },
    setSalesRecommendationsForYear(state, value) {
      state.salesRecommendationsForYear = value;
    },
  },
  getters: {
    currentSalesRecommendationMonth() {
      const currentMonth = new Date().getMonth();
      const salesRecMonth = ((currentMonth + 5) % 12) + 1;
      return salesRecMonth;
    },
    calculateTotalForRecommendationYear(state, getters) {
      /**
       * Calculate the total sales recommendations for a given year.
       * @param {{
       *  sales_recommendations: {
       *   month: number,
       *   sold_percent: number,
       *  }[],
       *  sold_percent: number,
       *  on_deck_percent: number,
       *  preharvest_percent: number
       * }} year The year to calculate the total for.
       * @param {boolean} includeFuture Whether to include future sales recommendations.
       * @returns {number} The total sales recommendation percent for the given year.
       */
      function calculate(year, includeFuture = false) {
        const currentMonth = getters.currentSalesRecommendationMonth;
        const percent = year.sales_recommendations
          .filter((sr) => includeFuture || sr.month <= currentMonth)
          .reduce((acc, sr) => acc + sr.sold_percent, 0);
        return percent + year.sold_percent + year.on_deck_percent + year.preharvest_percent;
      }
      return calculate;
    },
    getAllSalesRecommendationTotals(state, getters) {
      /**
       * Returns sales recommendations with their totals.
       * @param {boolean} includeFuture - Whether to include future sales recommendations.
       *
       * @returns {{ commodity: number, crop_year: number, total: number }[]} the total sales recommendations for the given crop year and commodity.
       */
      function getRecommendations(includeFuture = false) {
        return state.salesRecommendations.map((year) => ({
          crop_year_id: year.crop_year_id,
          commodity_id: year.commodity_id,
          total: year.sold_percent,
        }));
      }
      return getRecommendations;
    },
    getSalesRecommendationTotal(state, getters) {
      /**
       * Returns sales recommendations for a given crop year and commodity.
       * @param {number} cropYear - The ID of the crop year to get sales recommendations for.
       * @param {number} commodity - The AAD commodity ID to get sales recommendations for.
       * @param {boolean} includeFuture - Whether to include future sales recommendations.
       *
       * @returns {number} the total sales recommendations for the given crop year and commodity.
       */
      function getRecommendation(cropYear, commodity, includeFuture = false) {
        const year = state.salesRecommendations.find((sr) => sr.crop_year_id === cropYear && sr.commodity_id === commodity);
        if (!year) {
          return 0;
        }
        return getters.calculateTotalForRecommendationYear(year, includeFuture);
      }
      return getRecommendation;
    },
    advancedAnalysisCommoditiesWithColors(state) {
      const commodities = {};
      Object.keys(state.advancedAnalysisCommodities).forEach((commId, idx) => {
        commodities[commId] = {
          ...state.advancedAnalysisCommodities[commId],
          color: colourPalette[idx % colourPalette.length],
        };
      });
      return commodities;
    },
  },
  actions: {
    async fetchAnalysisDashboardCommodities({ commit }) {
      try {
        const { data } = await postRequest('/get_analysis_dashboard_commodities/');
        commit('setAdvancedAnalysisCommodities', data);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },
    async fetchAnalysisDashboardCommoditiesIncludingMappings({ commit }) {
      try {
        const { commodities } = await postRequest('/analysis_dashboard/api/get_aad_commodities_and_mappings/');
        commit('setAdvancedAnalysisCommoditiesWithMappings', commodities);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },
    async fetchAllSalesRecommendations({ commit }) {
      try {
        const { years } = await postRequest('/analysis_dashboard/api/get_sales_recommendations/');
        commit('setSalesRecommendations', years);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },
    async fetchAllSmartPricesForYear({ commit }, crop_year) {
      try {
        const { smart_prices } = await postRequest(`/analysis_dashboard/api/get_smart_prices/${crop_year}/`);
        commit('setSmartPricesForYear', smart_prices);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },
    async fetchAllSalesRecommendationsForYear({ commit }, crop_year) {
      try {
        const { years } = await postRequest(`/analysis_dashboard/api/get_sales_recommendations/${crop_year}/`);
        commit('setSalesRecommendationsForYear', years);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },
    async fetchLibraryLinks({ commit }) {
      try {
        const links = await postRequest('/analysis_dashboard/api/get_aad_commodity_library_mapping/');
        commit('setLibraryLinks', links);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },
    async fetchPriceMapLinks({ commit }) {
      try {
        const links = await postRequest('/analysis_dashboard/api/get_aad_commodity_price_map_data/');
        commit('setPriceMapLinks', links);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },
    async fetchAllSmartPriceRegions({ commit }) {
      try {
        const regions = await postRequest('/analysis_dashboard/api/get_all_smart_price_regions/');
        commit('setSmartPriceRegions', regions);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },
    async upsertSmartPrices({ dispatch }, payload) {
      try {
        await postRequest('/analysis_dashboard/api/upsert_smart_prices/', payload);
        const cropYear = payload['smart_prices'][0].crop_year;
        dispatch('fetchAllSmartPricesForYear', cropYear);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },
    async upsertSalesRecommendations({ dispatch }, payload) {
      try {
        await postRequest('/analysis_dashboard/api/upsert_sales_recommendations/', payload);
        const cropYear = payload['sales_recommendations'][0].year.crop_year;
        dispatch('fetchAllSalesRecommendationsForYear', cropYear);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },
  },
};
