<template>
  <header class="mb-4">
    <div class="d-flex">
      <!-- Title -->
      <div class="d-flex flex-wrap flex-1 overflow-hidden align-center">
        <h5 class="text-h5 text-break clamped">
          {{ title }}
        </h5>

        <!-- Info -->
        <div class="d-flex justify-end flex-grow-1">
          <template v-if="this.$slots.info">
            <slot name="info" />
          </template>
          <template v-else-if="info">
            <span class="align-self-center">
              {{ info }}
            </span>
          </template>
        </div>
      </div>

      <!-- Close Button -->
      <v-btn
        v-if="!permanent"
        icon
        class="ml-2"
        @click="handleClickClose"
      >
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </div>

    <!-- Subtitle -->
    <div>
      <template v-if="this.$slots.subtitle">
        <slot name="subtitle" />
      </template>
      <template v-else-if="subtitle">
        <span class="subtitle-2 primary-grey--text text-truncate">
          {{ subtitle }}
        </span>
      </template>
    </div>
  </header>
</template>

<script>
export default {
  name: 'SlideDrawerHeader',

  props: {
    title: {
      type: String,
      required: true,
    },

    subtitle: {
      type: String,
      required: true,
    },

    info: {
      type: String,
      required: true,
    },

    permanent: {
      type: Boolean,
      required: true,
    },
  },

  methods: {
    handleClickClose() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.clamped {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
